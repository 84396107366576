import { QueryDTO } from '@rossum/mdh-api-client';
import { Button, CircularProgress } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { matchPath, useHistory } from 'react-router';
import { DeleteConfirmationDialog } from '../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import { notify } from '../../toaster';
import { useDatasetContext } from '../context';
import { useDeleteDataset } from '../hooks/useDeleteDataset';
import { datasetSettingsPath, datasetsPath } from '../routes';

export const DeleteButton = ({ query }: { query?: QueryDTO }) => {
  const intl = useIntl();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const { mutate: deleteDataset, isLoading } = useDeleteDataset();

  const { registerTask, datasetTasks } = useDatasetContext();

  const isPollingTask = datasetTasks.some(
    task => task.datasetName === query?.query.dataset_name
  );

  const handleSubmit = (query: QueryDTO) => {
    handleClose();

    deleteDataset(
      { query },
      {
        onSuccess: response => {
          if (response.taskId) {
            registerTask({
              type: 'delete',
              taskId: response.taskId,
              queryId: response.queryId,
              datasetName: response.datasetName,
              onSuccess: () => {
                notify.success({
                  title: intl.formatMessage(
                    {
                      id: 'features.datasets.context.taskNotification.delete.success.title',
                    },
                    { queryName: query.name }
                  ),
                });
                const match = matchPath(history.location.pathname, {
                  path: datasetSettingsPath(response.queryId),
                  exact: true,
                });

                const isUrlExpired = match?.isExact;
                if (isUrlExpired) history.push(datasetsPath());
              },
              onFailed: () =>
                notify.error({
                  title: intl.formatMessage(
                    {
                      id: 'features.datasets.context.taskNotification.delete.error.title',
                    },
                    { queryName: query.name }
                  ),
                }),
            });
          }
        },
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isUpdating = isPollingTask || isLoading;
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        endIcon={isLoading ? <CircularProgress size={14} /> : null}
        disabled={isUpdating}
        onClick={() => {
          if (!query) {
            history.push(datasetsPath());
          } else setOpen(true);
        }}
      >
        {intl.formatMessage({
          id: 'features.datasets.settings.header.button.delete',
        })}
      </Button>
      {query ? (
        <DeleteConfirmationDialog
          open={open}
          title={intl.formatMessage(
            {
              id: 'features.datasets.settings.dialog.title',
            },
            { queryName: query.name }
          )}
          onConfirm={() => handleSubmit(query)}
          onCancel={handleClose}
          description={intl.formatMessage({
            id: 'features.datasets.settings.dialog.text',
          })}
        />
      ) : null}
    </>
  );
};
