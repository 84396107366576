import { Autocomplete, TextField, Typography } from '@rossum/ui/material';
import { FieldDataset } from '../../../../master-data/types/FieldDataset';
import { AdditionalColumns } from './AdditionalColumns';

type DatasetColumnsProps = {
  columnOptions: string[];
  value: FieldDataset;
  onChange: (value: FieldDataset) => void;
  variant: 'enum' | 'lookup';
};

export const DatasetColumns = ({
  columnOptions,
  value,
  onChange,
  variant,
}: DatasetColumnsProps) => {
  return (
    <>
      {variant === 'lookup' ? (
        <Typography variant="h6">Select</Typography>
      ) : null}

      <Autocomplete
        options={columnOptions}
        renderInput={params => (
          <TextField
            {...params}
            label="ID column"
            variant="outlined"
            size="small"
          />
        )}
        value={value.id_column}
        onChange={(_, v) => {
          if (v) {
            onChange({ ...value, id_column: v });
          }
        }}
        size="small"
      />

      <Autocomplete
        options={columnOptions}
        renderInput={params => (
          <TextField
            {...params}
            label="Label column"
            variant="outlined"
            size="small"
          />
        )}
        value={value.label_column}
        onChange={(_, v) => {
          if (v) {
            onChange({ ...value, label_column: v });
          }
        }}
        size="small"
      />

      {variant === 'lookup' ? (
        <Autocomplete
          multiple
          options={columnOptions}
          renderInput={params => (
            <TextField
              {...params}
              label="Additional details (optional)"
              variant="outlined"
              size="small"
            />
          )}
          value={value.detail_columns}
          onChange={(_, v) => {
            onChange({ ...value, detail_columns: v });
          }}
          size="small"
        />
      ) : null}

      {variant === 'lookup' ? (
        <AdditionalColumns
          columnOptions={columnOptions}
          value={value.additional_columns}
          onChange={additional_columns =>
            onChange({ ...value, additional_columns })
          }
        />
      ) : null}
    </>
  );
};
