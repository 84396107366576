import { useEffect, useMemo, useState } from 'react';
import { debounceTime } from 'rxjs/operators';
import {
  replaceInputSubject,
  searchInputSubject,
  togglePreviewSubject,
} from '../../../containers/Footer/components/SearchAndReplace';
import { findAll } from '../../../lib/searchAndReplaceUtils';
import styles from '../style.module.sass';

export type OnValueChangeOptions = {
  setHumanValidationSource?: boolean;
  struct?: unknown;
};

type Props = {
  className: string;
  value: string;
};

const Highlighter = ({ className, value }: Props) => {
  const [highlighter, setHighlighter] = useState<string>('');
  const [replaceInput, setReplaceInput] = useState<string>('');
  const [toggle, setToggle] = useState<boolean>(false);

  useEffect(() => {
    const phraseSubscription = searchInputSubject
      .pipe(debounceTime(200))
      .subscribe(data => {
        setHighlighter(data);
      });
    const replaceInputSubscription = replaceInputSubject
      .pipe(debounceTime(200))
      .subscribe(data => {
        setReplaceInput(data);
      });
    const togglePreviewSubscription = togglePreviewSubject.subscribe(data => {
      setToggle(data);
    });

    return () => {
      phraseSubscription.unsubscribe();
      replaceInputSubscription.unsubscribe();
      togglePreviewSubscription.unsubscribe();
    };
  });

  const highlightedText = useMemo(() => {
    const chunks = findAll({
      autoEscape: true,
      caseSensitive: true,
      searchWords: [highlighter],
      textToHighlight: value,
    });

    return chunks.map((chunk, i) => {
      const { end, highlight, start } = chunk;

      const text = value.substr(start, end - start);
      if (highlight) {
        return toggle ? (
          <span
            className={styles.InputSelectedPhrase}
            key={`${replaceInput}-${start + end}-highlight`}
          >
            {replaceInput}
          </span>
        ) : (
          <span
            className={styles.InputSelectedPhrase}
            key={`${text}-${start + i}-normal`}
          >
            {text}
          </span>
        );
      }
      return text;
    });
  }, [highlighter, replaceInput, toggle, value]);

  return <div className={className}>{highlightedText}</div>;
};

export default Highlighter;
