import {
  Add,
  Close,
  ContentCopy,
  ContentPaste,
  Delete,
  InfoOutlined,
} from '@rossum/ui/icons';
import {
  Chip,
  darken,
  IconButton,
  lighten,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { produce } from 'immer';
import { chain, get, isEmpty, isUndefined, take } from 'lodash';
import { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { from, fromEvent, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import HelpLinkTooltip from '../../../../../../components/UI/HelpLinkTooltip/index';
import Input from '../../../../../../components/UI/Input';
import { ENUM_EDITOR_LINK } from '../../../../../../constants/values';
import { getEventPath } from '../../../../../../lib/DOM';
import { linebreak, link, white } from '../../../../../../lib/formaterValues';
import { C_KEY_CODE, V_KEY_CODE } from '../../../../../../lib/keyboard';
import { OpenModal } from '../../../../../../redux/modules/modal/actions';
import {
  EnumDatapointSchema,
  EnumOption,
} from '../../../../../../types/schema';
import { useCopyPastePermissions } from '../../lib/enumEditorHooks';

type LabelAndValue = {
  label: string;
  value: string;
};

const defaultOption: LabelAndValue = {
  value: '',
  label: '',
};

const isLabel = <T extends EventTarget>(path: T[]) =>
  path.some(
    el =>
      'className' in el &&
      typeof el.className === 'string' &&
      el.className.includes('LabelInput')
  );

const textToOptions = (text: string) =>
  chain(text)
    .split('\n')
    .map(row => {
      const option = row.split('\t');
      return { value: get(option, '[0]', ''), label: get(option, '[1]', '') };
    })
    .filter(item => {
      return !(isEmpty(item.value) && isEmpty(item.label));
    })
    .value();

type Props = {
  currentSchemaPart: EnumDatapointSchema;
  onClose: MouseEventHandler;
  onCurrentSchemaPartChange: (_schema: EnumDatapointSchema) => void;
  openModal: OpenModal;
};

const sharedIconButtonStyles: SxProps<Theme> = {
  color: theme => theme.palette.text.primary,
  backgroundColor: theme =>
    theme.palette.mode === 'dark'
      ? lighten(theme.palette.common.black, 0.3)
      : lighten(theme.palette.common.black, 0.85),
  '&:hover': {
    backgroundColor: theme =>
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.common.black, 0.5)
        : lighten(theme.palette.common.black, 0.7),
  },
};

const sharedIconDeleteButtonStyles: SxProps<Theme> = {
  backgroundColor: theme =>
    theme.palette.mode === 'dark'
      ? darken(theme.palette.error.main, 0.25)
      : lighten(theme.palette.error.main, 0.5),
  '&:hover': {
    backgroundColor: theme => theme.palette.error.main,
  },
};

const EnumEditor = ({
  openModal,
  currentSchemaPart,
  onClose,
  onCurrentSchemaPartChange,
}: Props) => {
  const [pasteIsHovered, setPasteIsHovered] = useState<boolean>(false);
  const [clipboardText, setClipboardText] = useState<string>('');
  const [optionsVersion, setOptionsVersion] = useState<number>(Date.now);
  const [currentLine, setCurrentLine] = useState<number | undefined>(undefined);
  const [previewOptions, setPreviewOptions] = useState<EnumOption[]>([]);

  const { pasteIsAllowed, pasteIsSupported } = useCopyPastePermissions();

  useEffect(() => {
    const clipboardTextSubscription =
      pasteIsAllowed &&
      from(navigator.clipboard.readText())
        .pipe(catchError(() => of('')))
        .subscribe((text: string) => setClipboardText(text));

    return () => {
      if (clipboardTextSubscription) clipboardTextSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    if (!clipboardText) return setPreviewOptions([]);

    return setPreviewOptions(textToOptions(clipboardText));
  }, [clipboardText]);

  const { options: optionsOrUndefined, label } = currentSchemaPart;
  const options = optionsOrUndefined ?? [];
  const showPreviewOption = pasteIsHovered && previewOptions.length;
  const currentOptions = showPreviewOption ? previewOptions : options;
  const emptyOptions = !currentOptions || !currentOptions.length;
  const withTmpOption = emptyOptions ? [defaultOption] : currentOptions;

  useEffect(() => {
    const copySubscription = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(
        filter(e => e.metaKey || e.ctrlKey),
        filter(({ keyCode }) => keyCode === C_KEY_CODE),
        map(getEventPath)
      )
      .subscribe(path => {
        if (currentLine !== undefined) {
          const value = get(withTmpOption[currentLine], [
            isLabel(path) ? 'label' : 'value',
          ]);
          navigator.clipboard.writeText(value).catch(() => {});
        }
      });
    return () => {
      copySubscription.unsubscribe();
    };
  });

  useEffect(() => {
    const pasteSubscription = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(
        filter(e => e.metaKey || e.ctrlKey),
        filter(({ keyCode }) => keyCode === V_KEY_CODE),
        filter(() => pasteIsAllowed),
        filter(() => !!clipboardText),
        tap(event => event.preventDefault()),
        map(getEventPath)
      )
      .subscribe(path => {
        if (currentLine !== undefined) {
          if (clipboardText.includes('\n')) {
            const newOptions = textToOptions(clipboardText);
            const headOptions = take(options, currentLine + 1) || [];
            return onCurrentSchemaPartChange({
              ...currentSchemaPart,
              options: [...headOptions, ...newOptions],
            });
          }

          if (clipboardText.includes('\t')) {
            const option = clipboardText.split('\t');
            const newOption = {
              value: get(option, '[0]', ''),
              label: get(option, '[1]', ''),
            };
            return onCurrentSchemaPartChange(
              produce(currentSchemaPart, draft => {
                // ensure options is not undefined
                draft.options = draft.options ?? [];
                draft.options[currentLine] = newOption;
              })
            );
          }

          return onCurrentSchemaPartChange(
            produce(currentSchemaPart, draft => {
              draft.options = draft.options ?? [];
              draft.options[currentLine] = {
                ...draft.options[currentLine],
                [isLabel(path) ? 'label' : 'value']: clipboardText,
              };
            })
          );
        }

        return undefined;
      });

    return () => {
      pasteSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    const onBlurSubscription = fromEvent(window, 'click')
      .pipe(
        filter(() => optionsVersion !== undefined),
        filter(
          event =>
            !getEventPath(event).some(element =>
              ['addOption', 'option'].includes(
                'id' in element && typeof element.id === 'string'
                  ? element.id
                  : ''
              )
            )
        )
      )
      .subscribe(() => setCurrentLine(undefined));
    return () => onBlurSubscription.unsubscribe();
  });

  const reRenderInputs = () => setOptionsVersion(Date.now);

  useEffect(() => {
    if (currentLine !== undefined && currentLine > withTmpOption.length - 1) {
      setCurrentLine(undefined);
    }
  }, [currentLine, options.length, withTmpOption.length]);

  const onChange =
    (index: number, type: 'value' | 'label') => (value: string) =>
      onCurrentSchemaPartChange(
        produce(currentSchemaPart, draft => {
          draft.options = draft.options ?? [];
          draft.options[index][type] = value;
        })
      );

  const onTmpChange = (type: 'value' | 'label') => (value: string) => {
    const newOption = { ...defaultOption, [type]: value };

    onCurrentSchemaPartChange(
      produce(currentSchemaPart, draft => {
        draft.options = draft.options ?? [];
        draft.options[0] = newOption;
      })
    );
  };

  const addLine = () => {
    const index = currentLine !== undefined ? currentLine + 1 : options.length;

    onCurrentSchemaPartChange(
      produce(currentSchemaPart, draft => {
        draft.options = draft.options ?? [];
        draft.options.splice(index, 0, defaultOption);
      })
    );

    setCurrentLine(index);
    reRenderInputs();
  };

  const onCopy = () => {
    const optionsText = options
      .map(({ value, label: _label }) => `${value}\t${_label}`)
      .join('\n');
    navigator.clipboard.writeText(optionsText).catch(() => {});
  };

  const onPaste = () => {
    if (pasteIsHovered && previewOptions.length) {
      onCurrentSchemaPartChange({
        ...currentSchemaPart,
        options: previewOptions,
      });
    }
  };

  const onRemove = (index: number) => () =>
    onCurrentSchemaPartChange(
      produce(currentSchemaPart, draft => {
        draft.options = draft.options ?? [];
        draft.options.splice(index, 1);
      })
    );

  const removeAll = () => {
    onCurrentSchemaPartChange({ ...currentSchemaPart, options: [] });
  };

  const pasteTooltip = pasteIsAllowed ? (
    previewOptions.length ? (
      <FormattedMessage
        id="components.enumEditor.paste"
        values={{
          linebreak,
          hint: (msg: ReactNode) => (
            <Typography
              component="span"
              sx={{
                fontSize: theme => theme.typography.pxToRem(13),
              }}
            >
              {msg}
            </Typography>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="components.enumEditor.noContent" />
    )
  ) : pasteIsSupported ? (
    <FormattedMessage id="components.enumEditor.pasteIsNotAllowed" />
  ) : (
    <FormattedMessage id="components.enumEditor.pasteIsNotSupported" />
  );

  return (
    <Stack
      direction="column"
      sx={{
        position: 'absolute',
        height: '100%',
        width: '480px',
        backgroundColor: theme => {
          // FIXME colors
          return theme.palette.mode === 'dark' ? '#27242c' : '#f1f0f4';
        },
        zIndex: 5,
      }}
    >
      <Stack
        direction="row"
        sx={{
          flex: '0 0 auto',
          justifyContent: 'space-between',
          padding: '12px 20px',
          backgroundColor: theme =>
            theme.palette.mode === 'dark'
              ? '#161719'
              : theme.palette.common.white,
        }}
      >
        <Typography variant="h5">{label}</Typography>
        <Stack sx={{ cursor: 'pointer' }} data-cy="schema-editor-close-button">
          <Close onClick={onClose} />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{
          padding: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <FormattedMessage id="components.enumEditor.enumOptions" />
          <Chip
            label={showPreviewOption ? previewOptions.length : options.length}
            sx={{
              marginLeft: '12px',
            }}
          />
          <HelpLinkTooltip
            id="components.enumEditor.helpTooltip"
            values={{ link: link(ENUM_EDITOR_LINK) }}
          />
        </Stack>

        <Stack direction="row" spacing={1} sx={{ cursor: 'pointer' }}>
          <Tooltip
            title={<FormattedMessage id="components.enumEditor.addItem" />}
          >
            <IconButton
              aria-label="add"
              onClick={addLine}
              id="addOption"
              data-cy="schema-editor-add-item"
              sx={sharedIconButtonStyles}
            >
              <Add sx={{ fontSize: theme => theme.typography.pxToRem(14) }} />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={<FormattedMessage id="components.enumEditor.copyToClip" />}
          >
            <IconButton
              aria-label="copy"
              onClick={onCopy}
              id="copyItem"
              data-cy="schema-editor-copy-item"
              sx={sharedIconButtonStyles}
            >
              <ContentCopy
                sx={{ fontSize: theme => theme.typography.pxToRem(14) }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip
            key={`{pasteTooltip-${!!previewOptions}`}
            title={isUndefined(previewOptions) ? '' : pasteTooltip}
          >
            <IconButton
              onClick={() => pasteIsAllowed && onPaste()}
              onMouseEnter={() => pasteIsAllowed && setPasteIsHovered(true)}
              onMouseLeave={() => pasteIsAllowed && setPasteIsHovered(false)}
              aria-label="paste"
              id="paste"
              data-cy="schema-editor-paste-item"
              sx={{
                ...sharedIconButtonStyles,
                ...((!pasteIsAllowed || !previewOptions.length) && {
                  opacity: '0.3',
                  cursor: 'not-allowed',
                }),
              }}
            >
              <ContentPaste
                sx={{ fontSize: theme => theme.typography.pxToRem(14) }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={<FormattedMessage id="components.enumEditor.deleteAll" />}
          >
            <IconButton
              aria-label="delete"
              onClick={() => {
                if (emptyOptions) return null;

                return openModal({
                  textId: 'enumEditor.removeAll',
                  onConfirm: removeAll,
                  values: {
                    white,
                  },
                });
              }}
              id="delete"
              data-cy="schema-editor-delete-item"
              sx={sharedIconDeleteButtonStyles}
            >
              <Delete
                sx={{ fontSize: theme => theme.typography.pxToRem(14) }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack
        sx={{
          padding: '20px',
          color: theme => theme.palette.text.primary,
          overflowY: 'auto',
        }}
      >
        {pasteIsAllowed && (
          <Stack
            sx={{
              display: 'block',
              a: {
                color: theme => theme.palette.primary.main,
                '&:hover': {
                  color: theme => darken(theme.palette.primary.main, 0.1),
                  textDecoration: 'underline',
                },
              },
            }}
          >
            <FormattedMessage
              id="components.enumEditor.info"
              values={{ link: link(`${ENUM_EDITOR_LINK}`), linebreak }}
            />
          </Stack>
        )}

        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              marginTop: '10px',
              width: '440',
              fontSize: theme => theme.typography.pxToRem(12),
            }}
          >
            <Stack
              direction="row"
              sx={{
                paddingLeft: '10',
                width: '200',
              }}
            >
              <FormattedMessage id="components.enumEditor.id" />

              <Tooltip
                title={
                  <Stack
                    sx={{
                      maxWidth: '220px',
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id="components.enumEditor.id.tooltip" />
                  </Stack>
                }
              >
                <InfoOutlined
                  sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    marginLeft: '2px',
                  }}
                />
              </Tooltip>
            </Stack>

            <Stack sx={{ width: '180px' }}>
              <FormattedMessage id="components.enumEditor.label" />
            </Stack>
          </Stack>

          {withTmpOption.map((option: EnumOption, i: number) => (
            <Stack
              id="option"
              // eslint-disable-next-line react/no-array-index-key
              key={`${i}${optionsVersion}`}
              direction="row"
              alignItems="center"
              sx={{
                marginTop: '10px',
                width: '440px',
                '&:hover': {
                  button: {
                    opacity: 1,
                    transition: 'opacity 1s',
                  },
                },
              }}
            >
              {/* FIXME Input below is still Rossum UI component - cannot be used SX */}
              <Stack
                direction="row"
                sx={{
                  '& .IdInput': {
                    width: '202px',
                    borderRadius: '10px 0 0 10px',
                  },
                  '& .LabelInput': {
                    width: '202px',
                    borderRadius: '0px 10px 10px 0px',
                  },
                }}
              >
                <Input
                  autoFocus={i === currentLine}
                  className="IdInput"
                  onChange={
                    emptyOptions ? onTmpChange('value') : onChange(i, 'value')
                  }
                  onFocus={() => setCurrentLine(i)}
                  placeholder="components.enumEditor.id.placeholder"
                  value={option.value}
                />

                <Input
                  className="LabelInput"
                  onChange={
                    emptyOptions ? onTmpChange('label') : onChange(i, 'label')
                  }
                  onFocus={() => setCurrentLine(i)}
                  placeholder="components.enumEditor.label.placeholder"
                  value={option.label}
                />
              </Stack>

              <IconButton
                aria-label="delete"
                onClick={onRemove(i)}
                data-cy="schema-editor-delete-field"
                className="optionDeleteIcon"
                sx={{
                  ...sharedIconDeleteButtonStyles,
                  marginLeft: '10px',
                  opacity: 0,
                }}
              >
                <Delete
                  sx={{ fontSize: theme => theme.typography.pxToRem(14) }}
                />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EnumEditor;
