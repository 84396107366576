import { Button, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { Link as RouterLink } from 'react-router-dom';
import { contactSupportLink, statusPageURL } from '../../constants/values';
import { link } from '../../lib/formaterValues';
import * as animationData from './animations/error.json';

const LoginFailed = () => {
  const intl = useIntl();

  return (
    <>
      <Lottie
        loop
        play
        animationData={animationData}
        style={{
          height: '14vw',
          width: '14vw',
        }}
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Typography variant="h5" color="text.secondary" fontWeight="bold">
          {intl.formatMessage({
            id: `containers.errorPage.login-failed.title`,
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          {intl.formatMessage({
            id: `containers.errorPage.login-failed.text`,
          })}
        </Typography>
      </Stack>
      <Button
        component={RouterLink}
        to="/"
        color="secondary"
        variant="outlined"
        sx={{ mt: 2 }}
        data-cy="error-page-back-button"
      >
        {intl.formatMessage({ id: 'components.user.recovery.back' })}
      </Button>

      <Typography color="text.secondary" fontSize="small" sx={{ mt: '105px' }}>
        {intl.formatMessage(
          { id: 'containers.maintenance.badge.text1' },
          {
            contactLink: link(contactSupportLink, {
              color: 'inherit',
            }),
            statusLink: link(statusPageURL, { color: 'inherit' }),
          }
        )}
      </Typography>
    </>
  );
};

export default LoginFailed;
