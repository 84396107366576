import { Route, Switch } from 'react-router';
import { DatasetContext } from './context';
import { DatasetAddImported } from './dataset-add-imported/DatasetAddImported';
import { DatasetDetail } from './dataset-detail/DatasetDetail';
import { DatasetList } from './dataset-list';
import { DatasetSettings, DatasetSettingsContent } from './dataset-settings';
import {
  datasetAddImported,
  datasetCreateRoute,
  datasetSettingsRoute,
  datasetsPath,
  datasetTableRoute,
} from './routes';

export const DatasetsRoutes = () => {
  return (
    <DatasetContext>
      <Switch>
        <Route path={datasetsPath()} exact component={DatasetList} />
        <Route
          path={datasetCreateRoute()}
          exact
          component={DatasetSettingsContent}
        />
        <Route
          path={datasetAddImported()}
          exact
          component={DatasetAddImported}
        />
        <Route path={datasetTableRoute()} exact component={DatasetDetail} />
        <Route
          path={datasetSettingsRoute()}
          exact
          component={DatasetSettings}
        />
      </Switch>
    </DatasetContext>
  );
};
