import { useFlag } from '@unleash/proxy-client-react';
import z from 'zod';
import { DEV_FEATURES_ENABLED } from '../constants/config';
import { parseAndValidate } from '../utils/jsonParse';
import { unleashClient, UnleashFlagName } from './Unleash';

const UNLEASH_OVERRIDES_KEY = 'localUnleashOverrides';
const overridesSchema = z.record(z.boolean());

export const getLocalUnleashOverrides = () => {
  const overrides = DEV_FEATURES_ENABLED
    ? parseAndValidate(
        localStorage.getItem(UNLEASH_OVERRIDES_KEY),
        overridesSchema,
        {}
      )
    : {};

  return overrides;
};

const getLocalUnleashOverride = (name: UnleashFlagName): null | boolean => {
  const overrides = getLocalUnleashOverrides();

  return overrides[name] === undefined ? null : Boolean(overrides[name]);
};

export const useFeatureFlag = (name: UnleashFlagName) =>
  getFeatureFlagValue(name, useFlag);

/**
 * This function is intended for use outside of React,
 * for example in epics.
 */
export const isFeatureFlagEnabled = (
  name: UnleashFlagName,
  useOverrides = true
) => getFeatureFlagValue(name, n => unleashClient.isEnabled(n), useOverrides);

const getFeatureFlagValue = (
  name: UnleashFlagName,
  readFromUnleash: (name: string) => boolean,
  useOverrides = true
) => {
  // It's important that readFromUnleash is always called the same number of times,
  // otherwise we can break the rules of hooks (if called from useFeatureFlag).
  const valueFromUnleash = readFromUnleash(name);
  const antivalueFromUnleash = readFromUnleash(`anti-${name}`);
  const override = useOverrides ? getLocalUnleashOverride(name) : undefined;

  // TODO: REMOVE AFTER DEMO
  if (name === 'ac-5499-new-sidebar') {
    return true;
  }

  return override ?? (valueFromUnleash && !antivalueFromUnleash);
};
