import { createReactComponent } from '@tabler/icons-react';

export const IconCustomDatabaseCheck = createReactComponent(
  'outline',
  'customDatabaseCheck',
  'CustomDatabaseCheck',
  [
    [
      'path',
      {
        key: 'customDatabaseCheck-path',
        d: 'M3.33325 5C3.33325 6.38083 6.31825 7.5 9.99992 7.5C13.6816 7.5 16.6666 6.38083 16.6666 5M3.33325 5C3.33325 3.61917 6.31825 2.5 9.99992 2.5C13.6816 2.5 16.6666 3.61917 16.6666 5M3.33325 5V10M16.6666 5V10M3.33325 10C3.33325 11.3808 6.31825 12.5 9.99992 12.5C10.3983 12.5 10.7891 12.4867 11.1683 12.4617M3.33325 10V15C3.33325 16.2717 5.86659 17.3217 9.14325 17.4792M12.5001 15.8334L14.1667 17.5L17.5001 14.1667',
      },
    ],
  ]
);
