import { Stack } from '@rossum/ui/material';
import React from 'react';
import { SidebarSectionTitle } from '../sidebar-items/shared/SidebarSectionTitle';

type SidebarContainerProps = {
  children: React.ReactNode;
  title: string;
};

// TODO: @sidebarV2 can we find a way to use this for items sections as well? Is it a good abstraction?
export const SidebarContainer = ({
  children,
  title,
}: SidebarContainerProps) => {
  return (
    <>
      <SidebarSectionTitle title={title} />
      <Stack spacing={2} sx={{ px: 2 }}>
        {children}
      </Stack>
    </>
  );
};
