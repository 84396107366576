import { Stack, Typography } from '@rossum/ui/material';

type SidebarItemLabelProps = {
  label: string;
  sublabel?: string;
  variant?: 'primary' | 'secondary';
  isRequired?: boolean;
};

export const SidebarItemLabel = ({
  label,
  sublabel,
  variant = 'primary',
  isRequired = false,
}: SidebarItemLabelProps) => {
  const labelNode = (
    <Typography variant="body2" color="text.secondary">
      {label}
      {isRequired ? (
        <Typography color="error" component="span">
          *
        </Typography>
      ) : null}
    </Typography>
  );

  const sublabelNode = (
    <Typography variant="caption" color="text.secondary">
      {sublabel}
    </Typography>
  );

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        opacity: theme =>
          variant === 'secondary' ? theme.palette.action.disabledOpacity : 1,
      }}
    >
      {sublabelNode ? (
        <Stack spacing={0}>
          {labelNode}
          {sublabelNode}
        </Stack>
      ) : (
        labelNode
      )}
    </Stack>
  );
};
