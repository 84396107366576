import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryDTO } from 'libs/mdh-api-client/src';
import { useEffect } from 'react';
import { isIncludedIn } from 'remeda';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { GET_QUERIES_QUERY_KEYS, useGetQueries } from './useGetQueries';

export const useCleanupDatasets = () => {
  const queryClient = useQueryClient();
  const { data: queries } = useGetQueries();

  const { mutate: cleanup } = useMutation({
    mutationFn: async ({ queries }: { queries: QueryDTO[] }) => {
      const datasets = await datasetApi
        .getDatasetsApiV2DatasetGet(getDatasetRequestConfig())
        .then(res => res.data.datasets);

      const existingDatasetNames = datasets.map(
        dataset => dataset.dataset_name
      );

      const queriesToDelete = queries.filter(
        query => !isIncludedIn(query.query.dataset_name, existingDatasetNames)
      );

      return Promise.all(
        queriesToDelete.map(query =>
          datasetApi.deleteQueryApiV2QueryQueryIdDelete(
            query.id,
            getDatasetRequestConfig()
          )
        )
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: GET_QUERIES_QUERY_KEYS });
    },
  });

  useEffect(() => {
    if (queries) {
      cleanup({
        queries: queries.filter(
          query => query.metadata?.delete_pending === true
        ),
      });
    }
  }, [cleanup, queries]);
};
