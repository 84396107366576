import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { getTaskIdFromResponse } from '../utils';
import { GET_QUERIES_QUERY_KEYS } from './useGetQueries';

export const useCreateDataset = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      queryName,
      data,
    }: {
      queryName: string;
      data: DSResultsResponseResultsItem[];
    }) => {
      const datasetName = `${queryName}-${v4()}`;

      // Additionally, create a query beforehand.
      const queryResponse = await datasetApi.createQueryApiV2QueryPost(
        {
          name: queryName,
          query: {
            dataset_name: datasetName,
            type: 'dataset_find',
            find: { query: {} },
          },
          // Infer columns from the created data.
          columns: Object.keys(data[0] ?? {}).map(name => ({
            name,
            type: 'string',
          })),
          metadata: {
            type: 'manual',
          },
        },
        getDatasetRequestConfig()
      );

      return datasetApi
        .createDatasetApiV1DatasetDatasetNamePost(
          datasetName,
          {
            encoding: 'UTF-8',
            file: new Blob([JSON.stringify(data)], {
              type: 'application/json',
            }),
          },
          getDatasetRequestConfig()
        )
        .then(response => {
          return {
            taskId: getTaskIdFromResponse(response),
            datasetName,
            queryId: queryResponse.data.id,
          };
        });
    },
    onError: (_, { queryName }) => {
      notify.error({
        title: intl.formatMessage(
          {
            id: 'features.datasets.context.taskNotification.create.error.title',
          },
          { queryName }
        ),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: GET_QUERIES_QUERY_KEYS });
    },
  });
};
