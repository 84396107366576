import { useQuery } from '@tanstack/react-query';
import { mdh, mdhDefaults } from '../mdh';
import { getQueryType } from '../utils/getQueryType';

export const useDatasetQueries = () => {
  return useQuery({
    queryKey: ['@MDH', 'getQueriesApiV2QueryGet'],
    queryFn: () => {
      return mdh.getQueriesApiV2QueryGet({
        ...mdhDefaults(),
      });
    },
    select: response =>
      response.data.queries.filter(query => getQueryType(query) !== 'imported'),
  });
};
