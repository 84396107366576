import * as z from 'zod';

const datasetValue = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('const'),
    const: z.string(),
  }),
  z.object({
    type: z.literal('field'),
    field: z.string(),
  }),
]);

const datasetFilterSchema = z.object({
  column_name: z.string(),
  operator: z.string(),
  value: datasetValue,
});

const datasetSchema = z.object({
  dataset_name: z.string(),
  id_column: z.string(),
  label_column: z.string(),
  detail_columns: z.array(z.string()).default([]),
  filters: z.array(datasetFilterSchema).default([]),
  arguments: z.record(z.string(), datasetValue).default({}),
  additional_columns: z
    .array(z.object({ column: z.string(), label: z.string() }))
    .default([]),
});

const valueSchema = z.object({
  dataset: datasetSchema,
});

const parseJsonPreprocessor = (value: unknown, ctx: z.RefinementCtx) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch (e) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: (e as Error).message,
      });
    }
  }

  return value;
};

export const tryToGetDatasetOptions = (description: string) => {
  return z
    .preprocess(parseJsonPreprocessor, valueSchema)
    .safeParse(description);
};

export type FieldDataset = z.TypeOf<typeof datasetSchema>;
export type FieldDatasetFilter = z.TypeOf<typeof datasetFilterSchema>;
export type FieldDatasetValue = z.TypeOf<typeof datasetValue>;
