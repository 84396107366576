import { SchemaSection } from '@rossum/api-client/schemas';
import { QueryDTO } from '@rossum/mdh-api-client';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { getSchemaFields } from '../../../../formulas/FormulaEditor/helpers';
import { FieldDataset } from '../../../../master-data/types/FieldDataset';
import { getQueryType } from '../../../../master-data/utils/getQueryType';
import { ArgumentAutocomplete } from './ArgumentAutocomplete';
import { DatasetFilters } from './DatasetFilterPopoverContent';
import { getArgumentOptions, getColumnOptions } from './helpers/getQueryInfo';

type DatasetOptionsProps = {
  queries: QueryDTO[] | undefined;
  value: FieldDataset;
  onChange: (value: FieldDataset) => void;
  variant: 'enum' | 'lookup';
  schemaContent: SchemaSection[];
};

export const DatasetOptions = ({
  queries,
  value,
  onChange,
  variant,
  schemaContent,
}: DatasetOptionsProps) => {
  const stableOption = useMemo(() => queries ?? [], [queries]);

  const schemaFieldOptions = useMemo(
    () => getSchemaFields(schemaContent),
    [schemaContent]
  );

  const query = useMemo(() => {
    return queries?.find(query => query.name === value.dataset_name) ?? null;
  }, [queries, value.dataset_name]);

  const argOptions = getArgumentOptions(query);
  const filterOptions = getColumnOptions(query);

  return (
    <>
      <Autocomplete
        getOptionLabel={query => query.name}
        options={stableOption}
        value={query}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, v) =>
          onChange({
            ...value,
            dataset_name: v?.name ?? '',
            id_column: '',
            label_column: '',
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            label="Dataset"
            variant="outlined"
            size="small"
          />
        )}
      />
      {argOptions.map(argOption => (
        <ArgumentAutocomplete
          key={argOption.name}
          argument={argOption}
          value={value.arguments[argOption.name]}
          onChange={newArgumentValue =>
            onChange({
              ...value,
              arguments: {
                ...value.arguments,
                [argOption.name]: newArgumentValue,
              },
            })
          }
          fieldOptions={schemaFieldOptions.filter(
            op => op.category === 'datapoint'
          )}
        />
      ))}
      {variant === 'lookup' && query && getQueryType(query) === 'manual' ? (
        <DatasetFilters
          filterOptions={filterOptions}
          schemaContent={schemaContent}
          onChange={({ filters }) => {
            onChange({ ...value, filters });
          }}
          value={value.filters}
        />
      ) : null}
    </>
  );
};
