import { QueryDTO } from 'libs/mdh-api-client/src';

export const getQueryType = (query: QueryDTO) => {
  if (query.metadata?.type === 'imported') {
    return 'imported' as const;
  }
  if (query.metadata?.type === 'manual') {
    return 'manual' as const;
  }

  return 'virtual' as const;
};
