import { useSelector } from 'react-redux';
import { AnnotationAction } from '../../../../redux/modules/annotation/helpers';
import { userRoleNameSelector } from '../../../../redux/modules/user/selectors';
import { GroupRole } from '../../../../types/group';
import { useLabelsEnabled } from '../../../labels/hooks/useLabelsEnabled';

const allActions: AnnotationAction[] = [
  'delete',
  'downloadAndExport',
  'downloadFile',
  'download',
  'label',
  'move',
  'postpone',
  'purge',
  'reExtract',
  'reprocess',
  'reprocessConfirmed',
  'upload',
  'validate',
  'addAttachment',
];

const viewerActions: AnnotationAction[] = [
  'downloadAndExport',
  'download',
  'downloadFile',
];

const permittedActions: Record<GroupRole, AnnotationAction[]> = {
  viewer: viewerActions,
  annotator_limited: [
    'delete',
    'label',
    'move',
    'postpone',
    'purge',
    'reExtract',
    'reprocess',
    'reprocessConfirmed',
    'upload',
    'validate',
  ],
  annotator: allActions.filter(action => action !== 'purge'),
  approver: viewerActions,
  manager: allActions,
  admin: allActions,
  organization_group_admin: allActions,
};

export const usePermittedActions = () => {
  const userRole = useSelector(userRoleNameSelector);
  const isLabelsEnabled = useLabelsEnabled();

  return {
    // userRole can be undefined before groups are loaded thus fallback to an empty array
    permittedActions: permittedActions[userRole] ?? [],
    isLabelsEnabled,
  };
};
