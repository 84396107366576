import { Stack, Typography } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { sidebarConfidenceScoresEnabledSelector } from '../../../../../redux/modules/user/selectors';

type InactiveValueProps = {
  value: string;
  confidence?: string;
  dense?: boolean;
};

export const InactiveValue = ({
  dense = false,
  value,
  confidence,
}: InactiveValueProps) => {
  const confidenceScoresEnabled = useSelector(
    sidebarConfidenceScoresEnabledSelector
  );

  const valueNode = (
    <Typography
      width="100%"
      variant="body2"
      color="text.primary"
      whiteSpace="pre-wrap"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      {value || ' '}
    </Typography>
  );

  const confidenceNode =
    confidenceScoresEnabled && confidence ? (
      <Typography variant="caption" color="text.disabled">
        {confidence}
      </Typography>
    ) : null;

  return (
    <Stack
      spacing={0.5}
      sx={{
        py: dense ? 0 : 1,
        pl: '14px',
      }}
    >
      {valueNode}
      {confidenceNode}
    </Stack>
  );
};
