import { Stack } from '@rossum/ui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DISPLAYED_VALUE_CLASSNAME } from '../../../../constants/values';
import {
  currentDatapointSelector,
  datapointsSelector,
} from '../../../../redux/modules/datapoints/selector';
import { updateSuggestedOperationPositionAction } from '../../../../redux/modules/datapoints/suggestedOperations/actions';
import { replaceSuggestedOperationsSelector } from '../../../../redux/modules/datapoints/suggestedOperations/selector';
import { isSimpleDatapoint } from '../../../../redux/modules/datapoints/typedHelpers';
import { isFieldEditable } from '../../../../redux/modules/schema/helpers';
import { SimpleDatapointDataST } from '../../../../types/datapoints';
import { State } from '../../../../types/state';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { useValidationDialogCallbacks } from './callbacks/useValidationDialogCallbacks';
import { ConfirmButton } from './ui/ConfirmButton';
import { DeleteButton } from './ui/DeleteButton';
import { ValidationDialogWrapper } from './ui/ValidationDialogWrapper';
import ValidationTextField from './ValidationTextField';

type ValidationDialogProps = {
  parentWidth: number | undefined;
};

export const ValidationDialog = ({ parentWidth }: ValidationDialogProps) => {
  const {
    handleDatapointValueChange,
    handleDatapointConfirm,
    handleDatapointsDelete,
  } = useValidationDialogCallbacks();

  const dispatch = useDispatch();

  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);

  const currentDatapoint = useSelector(currentDatapointSelector);
  const activeDatapoint =
    currentDatapoint && isSimpleDatapoint(currentDatapoint)
      ? currentDatapoint
      : undefined;
  const readonly = useSelector((state: State) => state.ui.readOnly);
  const messages = useSelector((state: State) => state.datapoints.messages);

  const datapoints = useSelector(datapointsSelector);

  const selectedDatapoints = datapoints
    .filter(dp => selectedDatapointIds.includes(dp.id))
    .filter(isSimpleDatapoint);

  const suggestedOperations = useSelector(replaceSuggestedOperationsSelector);

  const getTableOperation = (dp: SimpleDatapointDataST) => {
    const suggestedOperation = suggestedOperations[dp.id];
    return suggestedOperation?.source === 'table'
      ? suggestedOperation
      : undefined;
  };

  const onValueChange = (dp: SimpleDatapointDataST, value: string) => {
    if (getTableOperation(dp)) {
      dispatch(
        updateSuggestedOperationPositionAction(dp.id, {
          content: { value },
        })
      );
      return;
    }

    handleDatapointValueChange(
      dp.id,
      dp.meta.index,
      value,
      dp.content?.value ?? ''
    );
  };

  const onConfirm = () => {
    if (selectedDatapoints.length) {
      handleDatapointConfirm(selectedDatapoints);
    } else if (activeDatapoint) {
      handleDatapointConfirm([activeDatapoint], true);
    }
  };

  const onDelete = () => {
    if (selectedDatapoints.length) {
      handleDatapointsDelete(selectedDatapoints);
    } else if (activeDatapoint) {
      handleDatapointsDelete([activeDatapoint]);
    }
  };

  return (
    <ValidationDialogWrapper>
      {activeDatapoint && selectedDatapointIds.length === 0 && (
        <ValidationTextField
          className={DISPLAYED_VALUE_CLASSNAME}
          value={
            getTableOperation(activeDatapoint)?.value?.content.value ??
            activeDatapoint.content?.value ??
            ''
          }
          message={messages[activeDatapoint.id]}
          schemaLabel={activeDatapoint.schema?.label}
          onChange={e => onValueChange(activeDatapoint, e.target.value)}
          parentWidth={parentWidth}
          disabled={readonly || !isFieldEditable(activeDatapoint.schema)}
          size="small"
        />
      )}
      <Stack spacing={1.5} direction="row-reverse">
        <ConfirmButton
          keyName={selectedDatapointIds.length === 0 ? 'tab' : undefined}
          disabled={readonly}
          onClick={onConfirm}
        />
        <DeleteButton disabled={readonly} onClick={onDelete} />
      </Stack>
    </ValidationDialogWrapper>
  );
};
