import { QueryDTO } from '@rossum/mdh-api-client';
import { useHistory } from 'react-router';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { DatasetsHeader } from '../header';
import { datasetsPath } from '../routes';

export const DatasetVirtual = ({ query }: { query: QueryDTO }) => {
  const history = useHistory();

  return (
    <PageLayoutV2
      fullWidth
      renderHeader={props => (
        <DatasetsHeader
          {...props}
          title={query.name}
          description={query.query.dataset_name}
          breadcrumbs={[{ label: query.name }]}
          onBackButtonClicked={() => {
            history.push(datasetsPath());
          }}
        />
      )}
    >
      <pre>{JSON.stringify(query, null, 2)}</pre>
    </PageLayoutV2>
  );
};
