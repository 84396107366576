import { SchemaSection } from '@rossum/api-client/schemas';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as z from 'zod';
import { DEV_FEATURES_ENABLED } from '../../../../constants/config';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import { getSchemaFields } from '../../../formulas/FormulaEditor/helpers';

const preTagStyles: SxProps = {
  backgroundColor: 'background.paper',
  color: 'text.secondary',
  border: 'none',
};

const dumpp = (obj: Record<string, boolean>): string =>
  `{${Object.keys(obj)
    .map(k => `"${k}":${obj[k] ? 'True' : 'False'}`)
    .join(',')}}`;

export const BrainTiles = ({
  schemaId,
  formula,
  setFormula,
  schemaContent,
  errorMessage,
}: {
  schemaId: string;
  formula: string;
  setFormula: (formula: string) => void;
  schemaContent: SchemaSection[];
  errorMessage: string | undefined;
}) => {
  // TODO: Proper escaping with "\" so that python code does not crash.
  const makeField = (id: string) => `field.${id}`;
  const makeFormula = (
    id: string,
    relevantFields: string[],
    additionalInstructions: string,
    options: Record<string, boolean>
  ) =>
    `# brain: ${JSON.stringify({ id, relevantFields, additionalInstructions, options })}\nbrain(${makeField(id)}, [${relevantFields.map(makeField).join(', ')}], description="${additionalInstructions}", options=${dumpp(options)})`;

  const intl = useIntl();

  const schema = z.object({
    id: z.string(),
    relevantFields: z.array(z.string()),
    additionalInstructions: z.string(),
    options: z.record(z.string(), z.boolean()),
  });

  const parseJsonPreprocessor = (value: unknown, ctx: z.RefinementCtx) => {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (e) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: (e as Error).message,
        });
      }
    }

    return value;
  };

  const parsedValue = useMemo((): {
    id: string;
    relevantFields: string[];
    additionalInstructions: string;
    options: Record<string, boolean>;
  } => {
    const jsonPattern = /^# brain: ({.*})\n/;
    const match = formula.match(jsonPattern);

    if (match) {
      const datasetResult = z
        .preprocess(parseJsonPreprocessor, schema)
        .safeParse(match[1]);

      if (datasetResult.success) {
        return datasetResult.data;
      }
    }

    return {
      id: schemaId,
      relevantFields: [],
      additionalInstructions: '',
      options: {
        field_label: true,
        queue_name: false,
        workspace_name: false,
      },
    };
  }, [formula, schema, schemaId]);

  const schemaFieldOptions = useMemo(
    () => getSchemaFields(schemaContent),
    [schemaContent]
  );

  const schemaFields = useMemo(
    () =>
      schemaFieldOptions.filter(f =>
        parsedValue.relevantFields.includes(f.id)
      ) ?? schemaFieldOptions[0],
    [parsedValue.relevantFields, schemaFieldOptions]
  );

  return (
    <>
      <ContentGroup
        title={intl.formatMessage({
          id: 'features.queueSettings.fields.form.context.title',
        })}
        description={intl.formatMessage({
          id: 'features.queueSettings.fields.form.context.description',
        })}
      >
        <Stack gap={3}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage({
                id: 'features.queueSettings.fields.brain.meta.title',
              })}
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={parsedValue.options.field_label}
                  onChange={(_e, checked) => {
                    setFormula(
                      makeFormula(
                        parsedValue.id,
                        parsedValue.relevantFields,
                        parsedValue.additionalInstructions,
                        { ...parsedValue.options, field_label: checked }
                      )
                    );
                  }}
                />
              }
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.brain.meta.label',
              })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    parsedValue.options.queue_name ||
                    parsedValue.options.workspace_name
                  }
                  onChange={(_e, checked) => {
                    setFormula(
                      makeFormula(
                        parsedValue.id,
                        parsedValue.relevantFields,
                        parsedValue.additionalInstructions,
                        {
                          ...parsedValue.options,
                          queue_name: checked,
                          workspace_name: checked,
                        }
                      )
                    );
                  }}
                />
              }
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.brain.meta.queueName',
              })}
            />
          </FormGroup>
          <Autocomplete
            multiple
            size="small"
            options={schemaFieldOptions}
            getOptionLabel={option => `${option.label} (${option.id})`}
            value={schemaFields}
            onChange={(_e, vs) => {
              setFormula(
                makeFormula(
                  parsedValue.id,
                  vs.map(v => v.id),
                  parsedValue.additionalInstructions,
                  parsedValue.options
                )
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: 'features.queueSettings.fields.form.relevantFields.placeholder',
                })}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            )}
          />
          <TextField
            multiline
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.additionalInstructions.placeholder',
            })}
            value={parsedValue.additionalInstructions}
            onChange={e =>
              setFormula(
                makeFormula(
                  parsedValue.id,
                  parsedValue.relevantFields,
                  e.currentTarget.value,
                  parsedValue.options
                )
              )
            }
            size="small"
          />
        </Stack>
        {errorMessage ? (
          <Typography color="error" variant="body2">
            {errorMessage}
          </Typography>
        ) : null}
      </ContentGroup>
      {DEV_FEATURES_ENABLED ? (
        <>
          <Typography>Parsed values</Typography>
          <Box component="pre" sx={preTagStyles}>
            {JSON.stringify(parsedValue, null, 2)}
          </Box>

          <Typography>Generated formula</Typography>
          {formula ? (
            <Box component="pre" sx={preTagStyles}>
              {formula}
            </Box>
          ) : (
            'Empty'
          )}
        </>
      ) : null}
    </>
  );
};
