import { EditRelation } from '@rossum/api-client/relations';
import {
  IconAlertOctagon,
  IconBolt,
  IconBoltOff,
  IconCustomAlertOctagonOff,
  IconLibrary,
  IconLink,
  IconMail,
  IconScissors,
  IconSettings,
} from '@rossum/ui/icons/tabler';
import {
  Badge,
  Collapse,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  SvgIcon,
  Tooltip,
} from '@rossum/ui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as R from 'remeda';
import { useShallow } from 'zustand/react/shallow';
import { LineItemSettingsDialog } from '../../../../components/LineItemsControls/components/LineItemSettingsDialog';
import { EditDocumentsRestrictor } from '../../../../components/Restrictors';
import { isEmbedded } from '../../../../constants/config';
import { DrawerConfig } from '../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { constructDocumentUrl } from '../../../../lib/url';
import { relevantBlockersSelector } from '../../../../redux/modules/annotation/selectors';
import { allMessagesSelector } from '../../../../redux/modules/datapoints/selector';
import { safeOrganizationSelector } from '../../../../redux/modules/organization/selectors';
import { complexLineItemsEnabledSelector } from '../../../../redux/modules/ui/selectors';
import { Annotation } from '../../../../types/annotation';
import { State } from '../../../../types/state';
import { useFetchAttachmentRelation } from '../../../annotation-attachment/hooks/useFetchAttachmentRelation';
import { useRelatedEmail } from '../../../emails/annotation-email-badge/useRelatedEmail';
import { attachmentsFeatureSelector } from '../../../pricing/selectors';
import { useDocumentStore } from '../../document-store/DocumentStore';

type SidebarTopbarProps = {
  scrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
  loading?: boolean;
};

export const SIDEBAR_TOPBAR_HEIGHT = 45;

const SidebarTopbar = React.memo(
  ({
    scrollableRef,
    annotation,
    onEmailThreadOpen,
    loading = false,
  }: SidebarTopbarProps) => {
    const intl = useIntl();

    const isOpen = useDocumentStore(state => state.sidebarState.topbarVisible);

    const setIsOpen = useDocumentStore(
      state => state.sidebarActions.setTopbarVisibility
    );

    const lastPosition = useRef(0);

    const handleScroll = useCallback(() => {
      const top = scrollableRef?.scrollTop ?? 0;

      const delta = top - lastPosition.current;

      lastPosition.current = top;

      // This was causing everything that depends on the store to rerender o_o
      if (delta > 5 && top > 100 && isOpen === true) {
        setIsOpen(false);
      }
      if (delta < -5 && isOpen === false) {
        setIsOpen(true);
      }
    }, [isOpen, scrollableRef?.scrollTop, setIsOpen]);

    useEffect(() => {
      if (scrollableRef) {
        scrollableRef.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollableRef) {
          scrollableRef.removeEventListener('scroll', handleScroll);
        }
      };
    }, [handleScroll, scrollableRef]);

    // Settings button
    const complexLineItemsEnabled = useSelector(
      complexLineItemsEnabledSelector
    );

    const [settingsOpen, setSettingsOpen] = useState(false);

    // Edit mode button
    const history = useHistory();

    const organization = useSelector(safeOrganizationSelector);

    const readOnly = useSelector((state: State) => state.ui.readOnly);

    const editRelation = useSelector((state: State) =>
      state.annotation.sideloads.relations.find(
        (r): r is EditRelation => r.type === 'edit'
      )
    );
    const documentWasEdited = !!editRelation;

    const disableEditMode =
      !!organization?.uiSettings?.features?.disableEditMode;

    // Global messages toggle
    const globalMessagesCount =
      useSelector(allMessagesSelector).all?.length ?? null;

    const { toggleMessages, messagesVisible } = useDocumentStore(
      useShallow(state => ({
        messagesVisible: state.sidebarState.messagesVisible,
        toggleMessages: state.sidebarActions.toggleMessages,
      }))
    );

    const handleMessagesToggleClick = useCallback(() => {
      toggleMessages();
    }, [toggleMessages]);

    // Automation blockers toggle
    const automationBlockersCount = useSelector(
      relevantBlockersSelector
    ).length;

    const { toggleBlockers, blockersVisible } = useDocumentStore(
      useShallow(state => ({
        blockersVisible: state.sidebarState.blockersVisible,
        toggleBlockers: state.sidebarActions.toggleBlockers,
      }))
    );

    const handleBlockersToggleClick = useCallback(() => {
      toggleBlockers();
    }, [toggleBlockers]);

    // Duplicates button
    const currentRelation = useSelector(
      (state: State) => state.relations.currentRelation
    );

    const openDrawer = useDocumentStore(state => state.openDrawer);

    // Attachments button
    const isAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

    const { attachmentRelation } = useFetchAttachmentRelation(
      annotation?.id ?? null
    );

    const attachmentsCount = attachmentRelation?.annotations.length;

    // Email button
    const { email } = useRelatedEmail({
      annotation: annotation ?? {
        relatedEmails: [],
        email: null,
        status: null,
      },
    });

    // TODO: Some refactoring to common components? Lot of repetition here
    return (
      <>
        <Slide in={isOpen} direction="down">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              width: '100%',
              position: 'absolute',
              top: 0,
              zIndex: 50,
              py: 0.5,
              px: 1,
              backgroundColor: theme => theme.palette.background.paper,
              borderBottom: '1px solid',
              borderRight: '1px solid',
              borderColor: theme => theme.palette.divider,
            }}
          >
            {loading || !annotation ? (
              R.range(0, 5).map(item => (
                <Skeleton key={item} sx={{ width: 28, height: 44 }} />
              ))
            ) : (
              <>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'components.sidebarV2.topbar.actions.settings',
                  })}
                >
                  <IconButton
                    size="medium"
                    onClick={() => setSettingsOpen(true)}
                  >
                    <SvgIcon fontSize="small">
                      <IconSettings />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>

                <EditDocumentsRestrictor>
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'components.sidebarV2.topbar.actions.editMode',
                    })}
                  >
                    <span>
                      <IconButton
                        size="medium"
                        disabled={
                          !(
                            (!readOnly || annotation.status === 'split') &&
                            !disableEditMode
                          )
                        }
                        onClick={() => {
                          history.push(
                            constructDocumentUrl({
                              id: annotation.id,
                              nestedPath: '/edit',
                            })
                          );
                        }}
                      >
                        <Badge
                          color="warning"
                          variant="dot"
                          invisible={!documentWasEdited}
                        >
                          <SvgIcon fontSize="small">
                            <IconScissors />
                          </SvgIcon>
                        </Badge>
                      </IconButton>
                    </span>
                  </Tooltip>
                </EditDocumentsRestrictor>

                <Collapse
                  orientation="horizontal"
                  in={
                    typeof globalMessagesCount === 'number' &&
                    globalMessagesCount > 0
                  }
                  unmountOnExit
                >
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'components.sidebarV2.topbar.actions.messages',
                    })}
                  >
                    <IconButton
                      size="medium"
                      onClick={handleMessagesToggleClick}
                    >
                      <Badge
                        badgeContent={
                          messagesVisible ? null : globalMessagesCount
                        }
                        color="warning"
                        // TODO @ui should this be global? :thinking:
                        sx={{
                          pointerEvents: 'none',
                        }}
                      >
                        <SvgIcon fontSize="small">
                          {messagesVisible ? (
                            <IconCustomAlertOctagonOff />
                          ) : (
                            <IconAlertOctagon />
                          )}
                        </SvgIcon>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Collapse>

                <Collapse
                  orientation="horizontal"
                  in={automationBlockersCount > 0}
                  unmountOnExit
                >
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'components.sidebarV2.topbar.actions.automationBlockers',
                    })}
                  >
                    <IconButton
                      size="medium"
                      onClick={handleBlockersToggleClick}
                    >
                      <Badge
                        badgeContent={
                          blockersVisible ? null : automationBlockersCount
                        }
                        color="warning"
                        sx={{
                          pointerEvents: 'none',
                        }}
                      >
                        <SvgIcon fontSize="small">
                          {blockersVisible ? <IconBoltOff /> : <IconBolt />}
                        </SvgIcon>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Collapse>

                {!isEmbedded() ? (
                  <Collapse
                    orientation="horizontal"
                    in={!!currentRelation && !!annotation}
                    unmountOnExit
                  >
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'components.sidebarV2.topbar.actions.duplicates',
                      })}
                    >
                      <IconButton
                        size="medium"
                        onClick={() =>
                          openDrawer({
                            drawer: 'duplicates',
                            annotationId: annotation.id,
                          })
                        }
                      >
                        <Badge
                          badgeContent={currentRelation?.annotations?.length}
                          color="warning"
                          max={9}
                          sx={{
                            pointerEvents: 'none',
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <IconLibrary />
                          </SvgIcon>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Collapse>
                ) : null}

                {isAttachmentsEnabled ? (
                  <Collapse
                    orientation="horizontal"
                    in={
                      !!annotation.url &&
                      !!attachmentsCount &&
                      attachmentsCount > 0
                    }
                    unmountOnExit
                  >
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'components.sidebarV2.topbar.actions.attachments',
                      })}
                    >
                      <IconButton
                        size="medium"
                        onClick={() =>
                          openDrawer({
                            drawer: 'attachments',
                            annotationId: annotation.id,
                          })
                        }
                      >
                        <Badge
                          badgeContent={attachmentsCount}
                          color="secondary"
                          max={9}
                          sx={{
                            pointerEvents: 'none',
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <IconLink />
                          </SvgIcon>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Collapse>
                ) : null}

                {!isEmbedded() ? (
                  <Collapse orientation="horizontal" in={!!email} unmountOnExit>
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'components.sidebarV2.topbar.actions.emails',
                      })}
                    >
                      <IconButton
                        size="medium"
                        onClick={
                          email
                            ? () =>
                                onEmailThreadOpen({
                                  highlightedEmailUrl: email.url,
                                })
                            : undefined
                        }
                      >
                        <Badge
                          badgeContent={annotation.relatedEmails.length}
                          color="secondary"
                          sx={{
                            pointerEvents: 'none',
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <IconMail />
                          </SvgIcon>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Collapse>
                ) : null}
              </>
            )}
          </Stack>
        </Slide>
        <LineItemSettingsDialog
          key={`${complexLineItemsEnabled}`}
          open={settingsOpen}
          onClose={() => setSettingsOpen(false)}
        />
      </>
    );
  }
);

SidebarTopbar.displayName = 'SidebarTopbar';

export { SidebarTopbar };
