import { SchemaSection } from '@rossum/api-client/schemas';
import {
  Button,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { ContentGroup } from '../../../../../ui/content-group/ContentGroup';
import { useDatasetQueries } from '../../../../master-data/hooks/useDatasetQueries';
import { FieldDataset } from '../../../../master-data/types/FieldDataset';
import { getQueryType } from '../../../../master-data/utils/getQueryType';
import { DatasetColumns } from './DatasetColumns';
import { DatasetOptions } from './DatasetOptions';
import { DatasetPreview } from './DatasetPreview';
import { getColumnOptions } from './helpers/getQueryInfo';

export const DatasetTileContent = ({
  variant,
  value,
  onChange,
  schemaContent,
}: {
  variant: 'enum' | 'lookup';
  value: FieldDataset;
  onChange: (value: FieldDataset) => void;
  schemaContent: SchemaSection[];
}) => {
  const { data: queries } = useDatasetQueries();

  const query = useMemo(() => {
    return queries?.find(query => query.name === value.dataset_name) ?? null;
  }, [queries, value.dataset_name]);

  const filterOptions = getColumnOptions(query);

  const [showPreview, setShowPreview] = useState(false);

  return variant === 'lookup' ? (
    <>
      <ContentGroup
        title="Lookup"
        description={
          <Stack spacing={1}>
            <Typography variant="inherit">
              Match document values with your dataset records using search
              rules.
            </Typography>
            {query ? (
              getQueryType(query) === 'manual' ? (
                <Typography variant="inherit">
                  Pick where to search in dataset (
                  <strong>Lookup column</strong>), what document value to search
                  for (<strong>Search key</strong>), and how exact the match
                  needs to be.
                </Typography>
              ) : (
                <Typography variant="inherit">
                  Enter required virtual dataset parameters to use predefined
                  matching logic.
                </Typography>
              )
            ) : null}
          </Stack>
        }
      >
        <DatasetOptions
          value={value}
          onChange={onChange}
          variant={variant}
          queries={queries}
          schemaContent={schemaContent}
        />
      </ContentGroup>
      {value.dataset_name ? (
        <ContentGroup
          title="Validation screen"
          description="Set which column appears in the validation screen select and what details display when opened.
And choose which dataset columns to show as fields and set their labels."
        >
          <DatasetColumns
            value={value}
            onChange={onChange}
            columnOptions={filterOptions.map(({ name }) => name)}
            variant={variant}
          />
          {/* TODO: Temporarily hide preview because it does not work with filters. */}
          {/* {value ? <DatasetPreview value={value} /> : null} */}
          {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}
        </ContentGroup>
      ) : null}
    </>
  ) : (
    <>
      <DatasetOptions
        value={value}
        onChange={onChange}
        variant={variant}
        queries={queries}
        schemaContent={schemaContent}
      />
      <DatasetColumns
        value={value}
        onChange={onChange}
        columnOptions={filterOptions.map(({ name }) => name)}
        variant={variant}
      />
      {/* Should be just fine since enums from datasets do not have filters enabled */}
      {value && value.id_column && value.label_column ? (
        <Stack spacing={2}>
          {showPreview ? <Divider /> : null}
          <Collapse in={showPreview}>
            <DatasetPreview value={value} />
          </Collapse>
          <Stack width="100%" direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              sx={{ maxWidth: 150 }}
              onClick={() => setShowPreview(!showPreview)}
            >
              {showPreview ? 'Hide preview' : 'Show preview'}
            </Button>
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};
