import {
  Button,
  Chip,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { settingsPath } from '../../../containers/Settings/helpers';
import { ItemTile, TILE_HEIGHT } from '../../../ui/item-tile/ItemTile';
import { ItemTileDescription } from '../../../ui/item-tile/ItemTileDescription';
import { getQueryType } from '../../master-data/utils/getQueryType';
import { EmptyList } from '../components/EmptyList';
import { DatasetsHeader } from '../header';
import { useCleanupDatasets } from '../hooks/useCleanupDatasets';
import { useGetQueries } from '../hooks/useGetQueries';
import { datasetCreateRoute, datasetTablePath } from '../routes';
import { pageSpacingStyles } from '../styles';

// const iconMap: (intl: IntlShape) => Record<TaskTypes, JSX.Element> = intl => ({
//   delete: (
//     <Chip
//       label={intl.formatMessage({
//         id: 'features.datasets.list.tileStatus.delete',
//       })}
//       size="small"
//       variant="outlined"
//       color="warning"
//     />
//   ),
//   update: (
//     <Chip
//       label={intl.formatMessage({
//         id: 'features.datasets.list.tileStatus.update',
//       })}
//       size="small"
//       variant="outlined"
//     />
//   ),
//   create: (
//     <Chip
//       label={intl.formatMessage({
//         id: 'features.datasets.list.tileStatus.create',
//       })}
//       size="small"
//       variant="outlined"
//     />
//   ),
// });

export const DatasetList = () => {
  const intl = useIntl();
  const history = useHistory();

  const { data: queries, isLoading } = useGetQueries();
  useCleanupDatasets();

  return (
    <PageLayoutV2
      renderHeader={props => (
        <DatasetsHeader
          {...props}
          breadcrumbs={[]}
          title={intl.formatMessage({
            id: 'features.datasets.list.header.title',
          })}
          description={intl.formatMessage({
            id: 'features.datasets.list.header.text',
          })}
          onBackButtonClicked={() => history.push(settingsPath())}
          buttons={[
            <Button
              key="add-dataset-button"
              data-cy="add-dataset-button"
              variant="contained"
              component={Link}
              to={datasetCreateRoute()}
            >
              {intl.formatMessage({
                id: 'features.datasets.list.header.button.addDataset',
              })}
            </Button>,
          ]}
        />
      )}
    >
      <Stack gap={0.5} sx={pageSpacingStyles}>
        {queries?.length === 0 ? (
          <EmptyList />
        ) : isLoading ? (
          <DatasetSkeletons />
        ) : (
          queries?.map(item => {
            // TODO: These guys should get periodically deleted.
            return (
              <ItemTile key={v4()} to={datasetTablePath(item.id)}>
                <ItemTileDescription
                  label={
                    <Stack direction="row" gap={1}>
                      <Typography
                        variant="h6"
                        sx={{ minWidth: 0 }}
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {item.name}
                      </Typography>
                      <Chip
                        size="small"
                        label={intl.formatMessage({
                          id: `features.datasets.list.datasetType.${getQueryType(item)}`,
                        })}
                      />
                    </Stack>
                  }
                  description=""
                  to={datasetTablePath(item.id)}
                  isFaded={item.metadata?.delete_pending === true}
                />
              </ItemTile>
            );
          })
        )}
      </Stack>
    </PageLayoutV2>
  );
};

const DatasetSkeletons = () => (
  <>
    {Array.from({ length: 10 }, (_, i) => i).map(id => (
      <Stack key={id} height={TILE_HEIGHT} component={Paper} py={1} px={4}>
        <Stack direction="row" gap={1}>
          <Skeleton variant="circular" height={30} width={30} />
          <Stack gap={0.5}>
            <Skeleton variant="rectangular" height={15} width={150} />
            <Skeleton variant="rectangular" height={15} width={50} />
          </Stack>
        </Stack>
      </Stack>
    ))}
  </>
);
