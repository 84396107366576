import {
  isSchemaDatapoint,
  isSchemaDatapointButton,
  isSchemaDatapointDate,
  isSchemaDatapointEnum,
  isSchemaDatapointNumber,
  isSchemaDatapointString,
  isSchemaSection,
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  SchemaDatapointButton,
  SchemaDatapointDate,
  SchemaDatapointEnum,
  SchemaDatapointNumber,
  SchemaDatapointString,
  SchemaItem,
  SchemaSection,
  SchemaSimpleMultivalue,
  SchemaTableMultivalue,
  SchemaUiConfigType,
} from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { preDefinedDescriptions } from '../../constants';
import { emptyDefaultValues, FieldsFormValues } from '../formModels';
import { PropertyEvolver } from './toFormValues.utils';
import { Evolver, isLineItemChild } from './utils';

type NonButtonDatapoint =
  | SchemaDatapointString
  | SchemaDatapointNumber
  | SchemaDatapointDate
  | SchemaDatapointEnum;

type SchemaSimpleMultivalueWithNonButtonDatapoint = SchemaSimpleMultivalue & {
  children: NonButtonDatapoint;
};

const isNonButtonDatapoint = (item: unknown): item is NonButtonDatapoint =>
  isSchemaDatapoint(item) && !isSchemaDatapointButton(item);

const isSchemaSimpleMultiValueWithNonButtonDatapoint = (
  item: unknown
): item is SchemaSimpleMultivalueWithNonButtonDatapoint =>
  isSchemaSimpleMultiValue(item) && isNonButtonDatapoint(item.children);

type ManualDatapoint = NonButtonDatapoint & {
  uiConfiguration: { type: 'manual' };
};

const isManualField = (item: unknown): item is ManualDatapoint =>
  isNonButtonDatapoint(item) && item.uiConfiguration?.type === 'manual';

type CapturedDatapoint = NonButtonDatapoint & {
  uiConfiguration: {
    type: 'captured';
  };
};

const isCapturedField = (item: unknown): item is CapturedDatapoint =>
  isNonButtonDatapoint(item) && item.uiConfiguration?.type === 'captured';

type FormulaDatapoint = NonButtonDatapoint & {
  uiConfiguration: { type: 'formula' };
};

const isFormulaField = (item: unknown): item is FormulaDatapoint =>
  isNonButtonDatapoint(item) && item.uiConfiguration?.type === 'formula';

type DataDatapoint = NonButtonDatapoint & { uiConfiguration: { type: 'data' } };

const isDataField = (item: unknown): item is DataDatapoint =>
  isNonButtonDatapoint(item) && item.uiConfiguration?.type === 'data';

type UnsetDatapoint = NonButtonDatapoint & {
  uiConfiguration: { type: null | undefined };
};

type BrainDatapoint = NonButtonDatapoint & {
  uiConfiguration: { type: 'brain' };
};

type LookupDatapoint = NonButtonDatapoint & {
  uiConfiguration: { type: 'lookup' };
};

const isBrainField = (item: unknown): item is BrainDatapoint =>
  isNonButtonDatapoint(item) && item.uiConfiguration?.type === 'brain';

const isLookupField = (item: unknown): item is LookupDatapoint =>
  isNonButtonDatapoint(item) && item.uiConfiguration?.type === 'lookup';

const isUnsetField = (item: unknown): item is UnsetDatapoint =>
  isNonButtonDatapoint(item) &&
  (item.uiConfiguration?.type === undefined ||
    item.uiConfiguration?.type === null);

const isValidType = (type: unknown): type is SchemaUiConfigType =>
  type === 'captured' ||
  type === 'data' ||
  type === 'manual' ||
  type === 'formula' ||
  type === 'brain' ||
  type === 'lookup';

const evolveFieldType: PropertyEvolver<'fieldType'> =
  (item, schemaPath) => def =>
    R.pipe(
      item,
      R.conditional(
        [
          isLineItemChild(schemaPath),
          R.piped(
            R.conditional(
              [isSchemaDatapointButton, R.constant('lineItemButton')],
              R.conditional.defaultCase(
                R.constant('lineItemSimpleValue' as const)
              )
            )
          ),
        ],
        [isSchemaSection, R.constant('section')],
        [isSchemaTableMultiValue, R.constant('lineItems')],
        [isSchemaSimpleMultiValue, R.constant('multivalue')],
        [isSchemaDatapointButton, R.constant('button' as const)],
        [isSchemaDatapoint, R.constant('simpleValue' as const)],
        R.conditional.defaultCase(R.constant(def))
      )
    );

// TODO: Simpler "extract" functions? Repeating types is annoying
// maybe something like `evolveProp` above
const extractId =
  (defaultValue: FieldsFormValues['field']['id']) =>
  (item: SchemaItem | SchemaSection) =>
    R.pipe(
      item,
      // for simple multivalues, we are using the child's ID to identify it
      R.conditional(
        [
          isSchemaSimpleMultiValue,
          R.pathOr(['children', 'id'] as const, defaultValue),
        ],
        R.conditional.defaultCase(() =>
          R.pipe(item, R.pathOr(['id'] as const, defaultValue))
        )
      )
    );

const evolveId: PropertyEvolver<'field.id'> = item => def =>
  R.pipe(
    item,
    R.conditional(
      // TODO: conditional type inference is broken in some cases:
      // as a workaround we need to use lamba functions and pass parameter explicitly
      // https://github.com/remeda/remeda/issues/847
      // https://github.com/remeda/remeda/issues/770
      [R.isNonNullish, data => extractId(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractLabel =
  (defaultValue: FieldsFormValues['field']['label']) =>
  (item: SchemaItem | SchemaSection) =>
    R.pipe(item, R.pathOr(['label'] as const, defaultValue));

const evolveLabel: PropertyEvolver<'field.label'> = item => def =>
  R.pipe(
    item,
    R.conditional(
      [R.isNonNullish, data => extractLabel(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractDescription =
  (defaultValue: FieldsFormValues['field']['description']) =>
  (item: SchemaItem | SchemaSection) => {
    const customDescription = item.id
      ? preDefinedDescriptions[item.id as keyof typeof preDefinedDescriptions]
      : undefined;

    return R.pipe(
      item,
      R.pathOr(['description'] as const, customDescription ?? defaultValue)
    );
  };

const evolveDescription: PropertyEvolver<'field.description'> = item => def =>
  R.pipe(
    item,
    R.conditional(
      [R.isNonNullish, data => extractDescription(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractHidden =
  (defaultValue: FieldsFormValues['field']['hidden']) =>
  (item: SchemaItem | SchemaSection) =>
    R.pipe(item, R.pathOr(['hidden'] as const, defaultValue));

const evolveHidden: PropertyEvolver<'field.hidden'> = item => def =>
  R.pipe(
    item,
    R.conditional(
      [R.isNonNullish, data => extractHidden(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractExported =
  (defaultValue: FieldsFormValues['field']['exported']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(item, R.pathOr(['canExport'] as const, defaultValue));

const evolveExported: PropertyEvolver<'field.exported'> = item => def =>
  R.pipe(
    item,
    R.conditional(
      [isNonButtonDatapoint, data => extractExported(def)(data)],
      [
        isSchemaSimpleMultiValueWithNonButtonDatapoint,
        data => extractExported(def)(data.children),
      ],
      [isSchemaTableMultiValue, R.constant(false)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

// If possible, coalesce to simple value, otherwise fallback to null
const extractSimpleValue = (item: SchemaItem | SchemaSection | null) =>
  R.pipe(
    item,
    R.conditional(
      [isSchemaSimpleMultiValue, R.prop('children')],
      [isSchemaDatapoint, R.identity()],
      R.conditional.defaultCase(R.constant(null))
    )
  );

const extractRequired =
  (defaultValue: FieldsFormValues['field']['required']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(item, R.pathOr(['constraints', 'required'] as const, defaultValue));

const evolveRequired: PropertyEvolver<'field.required'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isNonButtonDatapoint, data => extractRequired(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractType =
  (defaultValue: FieldsFormValues['field']['valueSource']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(
      item,
      item => item.uiConfiguration?.type,
      R.conditional(
        [isValidType, R.identity()],
        [R.isNullish, R.constant('unset' as const)],
        R.conditional.defaultCase(R.constant(defaultValue))
      )
    );

const evolveValueSource: PropertyEvolver<'field.valueSource'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isNonButtonDatapoint, data => extractType(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractEditing =
  (defaultValue: FieldsFormValues['field']['editing']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(item, R.pathOr(['uiConfiguration', 'edit'] as const, defaultValue));

const resolveEditing =
  (defaultValue: FieldsFormValues['field']['editing']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(
      item,
      R.conditional(
        [isManualField, R.constant('enabled' as const)],
        [
          R.anyPass([isDataField, isCapturedField, isFormulaField]),
          extractEditing(defaultValue),
        ],
        R.conditional.defaultCase(R.constant(defaultValue))
      )
    );

const evolveEditing: PropertyEvolver<'field.editing'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isNonButtonDatapoint, data => resolveEditing(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractAiEngineFields =
  (defaultValue: FieldsFormValues['field']['aiEngineFields']) =>
  (item: NonButtonDatapoint | SchemaTableMultivalue) =>
    R.pipe(item, R.pathOr(['rirFieldNames'] as const, defaultValue));

const resolveAiEngineFields =
  (defaultValue: FieldsFormValues['field']['aiEngineFields']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(
      item,
      R.conditional(
        [
          R.anyPass([isCapturedField, isDataField, isUnsetField]),
          extractAiEngineFields(defaultValue),
        ],
        R.conditional.defaultCase(R.constant(defaultValue))
      )
    );

const evolveAiEngineFields: PropertyEvolver<'field.aiEngineFields'> =
  item => def =>
    R.pipe(
      item,
      R.conditional(
        [isSchemaTableMultiValue, data => extractAiEngineFields(def)(data)],
        [
          R.anyPass([isSchemaSimpleMultiValue, isSchemaDatapoint]),
          R.piped(
            extractSimpleValue,
            R.conditional(
              [isNonButtonDatapoint, data => resolveAiEngineFields(def)(data)],
              R.conditional.defaultCase(R.constant(def))
            )
          ),
        ],
        R.conditional.defaultCase(R.constant(def))
      )
    );

const nonNegativeNumericStringOr = (defaultValue: string) => (val: number) =>
  val < 0 ? defaultValue : String(val);

const extractThreshold =
  (defaultValue: FieldsFormValues['field']['scoreThreshold']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(
      item,
      R.pathOr(['scoreThreshold'] as const, -1),
      nonNegativeNumericStringOr(defaultValue)
    );

// helper to parse `threshold` based on value source
const resolveThreshold =
  (defaultValue: FieldsFormValues['field']['scoreThreshold']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(item, extractThreshold(defaultValue));

const evolveThreshold: PropertyEvolver<'field.scoreThreshold'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isNonButtonDatapoint, data => resolveThreshold(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractDataType =
  (defaultValue: FieldsFormValues['field']['dataType']) =>
  (item: NonButtonDatapoint) =>
    R.pipe(
      item,
      R.conditional(
        [isLookupField, R.constant('enum' as const)],
        R.conditional.defaultCase(dp =>
          R.pathOr(dp, ['type'] as const, defaultValue)
        )
      )
    );

const evolveDataType: PropertyEvolver<'field.dataType'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isLookupField, R.constant('enum')],
      [isNonButtonDatapoint, data => extractDataType(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractMinLength =
  (defaultValue: FieldsFormValues['field']['minLength']) =>
  (item: SchemaDatapointString) =>
    R.pipe(
      item,
      R.pathOr(['constraints', 'length', 'min'] as const, -1),
      nonNegativeNumericStringOr(defaultValue)
    );

const evolveMinLength: PropertyEvolver<'field.minLength'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isSchemaDatapointString, data => extractMinLength(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractMaxLength =
  (defaultValue: FieldsFormValues['field']['maxLength']) =>
  (item: SchemaDatapointString) =>
    R.pipe(
      item,
      R.pathOr(['constraints', 'length', 'max'] as const, -1),
      nonNegativeNumericStringOr(defaultValue)
    );

const evolveMaxLength: PropertyEvolver<'field.maxLength'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isSchemaDatapointString, data => extractMaxLength(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractExactLength =
  (defaultValue: FieldsFormValues['field']['exactLength']) =>
  (item: SchemaDatapointString) =>
    R.pipe(
      item,
      R.pathOr(['constraints', 'length', 'exact'] as const, -1),
      nonNegativeNumericStringOr(defaultValue)
    );

const evolveExactLength: PropertyEvolver<'field.exactLength'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isSchemaDatapointString, data => extractExactLength(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractRegex =
  (defaultValue: FieldsFormValues['field']['regex']) =>
  (item: SchemaDatapointString) =>
    R.pipe(
      item,
      R.pathOr(['constraints', 'regexp', 'pattern'] as const, defaultValue)
    );

const evolveRegex: PropertyEvolver<'field.regex'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isSchemaDatapointString, data => extractRegex(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractOptions =
  (defaultValue: FieldsFormValues['field']['options']) =>
  (item: SchemaDatapointEnum) =>
    R.pipe(item, R.pathOr(['options'] as const, defaultValue));

const evolveOptions: PropertyEvolver<'field.options'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isSchemaDatapointEnum, data => extractOptions(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractFormula =
  (defaultValue: FieldsFormValues['field']['formula']) =>
  (item: FormulaDatapoint | BrainDatapoint) =>
    R.pipe(item, R.pathOr(['formula'] as const, defaultValue));

const evolveFormula: PropertyEvolver<'field.formula'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isFormulaField, data => extractFormula(def)(data)],
      [isBrainField, data => extractFormula(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractFormat =
  (defaultValue: FieldsFormValues['field']['format']) =>
  (item: SchemaDatapointNumber | SchemaDatapointDate) =>
    R.pipe(item, R.pathOr(['format'] as const, defaultValue));

const evolveFormat: PropertyEvolver<'field.format'> = item => def =>
  R.pipe(
    item,
    extractSimpleValue,
    R.conditional(
      [isSchemaDatapointNumber, data => extractFormat(def)(data)],
      [isSchemaDatapointDate, data => extractFormat(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const extractUrl =
  (defaultValue: FieldsFormValues['field']['url']) =>
  (item: SchemaDatapointButton) =>
    R.pipe(item, R.pathOr(['popupUrl'] as const, defaultValue));

const evolveUrl: PropertyEvolver<'field.url'> = item => def =>
  R.pipe(
    item,
    R.conditional(
      [isSchemaDatapointButton, data => extractUrl(def)(data)],
      R.conditional.defaultCase(R.constant(def))
    )
  );

const makeEvolver = (
  item: SchemaSection | SchemaItem | null,
  schemaPath?: SchemaPath | null
): Evolver<FieldsFormValues> => {
  return {
    fieldType: evolveFieldType(item, schemaPath),
    field: {
      id: evolveId(item),
      label: evolveLabel(item),
      description: evolveDescription(item),
      hidden: evolveHidden(item),
      exported: evolveExported(item),
      required: evolveRequired(item),
      valueSource: evolveValueSource(item),
      editing: evolveEditing(item),
      aiEngineFields: evolveAiEngineFields(item),
      scoreThreshold: evolveThreshold(item),
      dataType: evolveDataType(item),
      minLength: evolveMinLength(item),
      maxLength: evolveMaxLength(item),
      exactLength: evolveExactLength(item),
      regex: evolveRegex(item),
      options: evolveOptions(item),
      formula: evolveFormula(item),
      format: evolveFormat(item),
      url: evolveUrl(item),
    },
  };
};

type SchemaPath = Array<string | number>;
// converts a given API model into default values for the form
export const toFormValues = (
  apiModel: SchemaSection | SchemaItem | null,
  schemaPath?: SchemaPath | null
): FieldsFormValues =>
  R.pipe(emptyDefaultValues, R.evolve(makeEvolver(apiModel, schemaPath)));
