import { SchemaSection } from '@rossum/api-client/schemas';
import { FieldType } from '@rossum/mdh-api-client';
import { IconTrash } from '@rossum/ui/icons/tabler';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useMemo, useRef } from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { isDeepEqual } from 'remeda';
import { getSchemaFields } from '../../../../formulas/FormulaEditor/helpers';
import {
  FieldDataset,
  FieldDatasetFilter,
} from '../../../../master-data/types/FieldDataset';

const precisionOptions = [
  { name: 'Exact', op: '$eq' },
  { name: 'Conservative', op: '$fuzzy_conservative' },
  { name: 'Dynamic', op: '$fuzzy_dynamic' },
];

// const operatorOptions = [
//   { label: 'Equals to', value: '$eq', worksFor: ['string', 'number', 'date'] },
//   {
//     label: 'Is greater than',
//     value: '$gt',
//     worksFor: ['string', 'number', 'date'],
//   },
//   {
//     label: 'Is is less than',
//     value: '$lt',
//     worksFor: ['string', 'number', 'date'],
//   },
//   {
//     label: 'Fuzzy (conservative)',
//     value: '$fuzzy_conservative',
//     worksFor: ['string'],
//   },
//   { label: 'Fuzzy (dynamic)', value: '$fuzzy_dynamic', worksFor: ['string'] },
// ] as const;

type FilterOption = {
  name: string;
  type: FieldType;
};

export const DatasetFilter = ({
  schemaContent,
  filterOptions,
  control,
  index,
  onRemove,
}: {
  schemaContent: SchemaSection[];
  filterOptions: FilterOption[];
  control: Control<Pick<FieldDataset, 'filters'>>;
  index: number;
  onRemove: () => void;
}) => {
  const columnName = useWatch({
    control,
    name: `filters.${index}.column_name`,
  });

  const schemaFieldOptions = useMemo(() => {
    const filterOption = filterOptions.find(
      column => column.name === columnName
    );
    return filterOption
      ? getSchemaFields(schemaContent)
          .filter(field => field.category === 'datapoint')
          .filter(
            field =>
              field.type === filterOption.type ||
              (field.type === 'enum' &&
                field.enumValueType === filterOption.type)
          )
      : [];
  }, [columnName, filterOptions, schemaContent]);

  const columnOptions = filterOptions.map(({ name }) => name);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{`Search for ${columnName}`}</Typography>
        <IconButton onClick={onRemove}>
          <SvgIcon>
            <IconTrash />
          </SvgIcon>
        </IconButton>
      </Stack>

      <Controller
        control={control}
        name={`filters.${index}.column_name`}
        render={({ field }) => (
          <Autocomplete
            disableClearable
            sx={{ flex: 0.5 }}
            options={columnOptions}
            renderInput={params => (
              <TextField
                {...params}
                label="Lookup column"
                variant="outlined"
                size="small"
              />
            )}
            value={field.value}
            onChange={(_, v) => {
              field.onChange(v);
            }}
            size="small"
          />
        )}
      />

      <Controller
        control={control}
        name={`filters.${index}.value.field`}
        render={({ field }) => (
          <Autocomplete
            value={schemaFieldOptions.find(op => op.id === field.value) ?? null}
            onChange={(_, v) => {
              if (v) {
                field.onChange(v.id);
              }
            }}
            size="small"
            options={schemaFieldOptions}
            getOptionLabel={option => `${option.label} (${option.id})`}
            renderInput={params => (
              <TextField
                {...params}
                label="Search key"
                placeholder="Choose field"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}
      />
      <FormControl>
        <FormLabel>
          <Typography variant="caption">Precision</Typography>
        </FormLabel>
        <Controller
          control={control}
          name={`filters.${index}.operator`}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onChange={(_, v) => field.onChange(v)}
            >
              {precisionOptions.map(option => (
                <FormControlLabel
                  key={option.op}
                  value={option.op}
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">{option.name}</Typography>}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    </Stack>
  );
};

export const DatasetFilters = ({
  schemaContent,
  filterOptions,
  onChange,
  value,
}: {
  schemaContent: SchemaSection[];
  filterOptions: FilterOption[];
  onChange: (values: Pick<FieldDataset, 'filters'>) => void;
  value: FieldDatasetFilter[];
}) => {
  const initialValue = useRef(value);

  const { control, watch } = useForm<Pick<FieldDataset, 'filters'>>({
    defaultValues: {
      filters: initialValue.current,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  });

  const filtersValue = watch();

  // Re-sync the value with the parent form whenever value changes.
  useEffect(() => {
    if (!isDeepEqual(filtersValue.filters, value)) {
      onChange(filtersValue);
    }
  }, [filtersValue, onChange, value]);

  return (
    <Stack spacing={2}>
      {fields.map((filter, index) => (
        <DatasetFilter
          key={filter.id}
          filterOptions={filterOptions}
          control={control}
          index={index}
          schemaContent={schemaContent}
          onRemove={() => remove(index)}
        />
      ))}
      <Stack direction="row" alignSelf="end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            append({
              column_name: '',
              operator: '$eq',
              value: { type: 'field', field: '' },
            });
          }}
        >
          Add search key
        </Button>
      </Stack>
    </Stack>
  );
};
