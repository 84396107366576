import { useRouteMatch } from 'react-router';
import { DATASET_ID_PARAM_KEY } from '../routes';

export const useGetDatasetParams = ({ route }: { route: string }) => {
  const match =
    useRouteMatch<Record<typeof DATASET_ID_PARAM_KEY, string>>(route);

  const queryId = match?.params.id;
  return { queryId };
};
