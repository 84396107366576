import { MatchRequest } from '@rossum/mdh-api-client';
import { useQuery } from '@tanstack/react-query';
import { mdh, mdhDefaults } from '../mdh';

export const useFindInDataset = (
  body: MatchRequest,
  { enabled }: { enabled: boolean }
) =>
  useQuery({
    queryKey: ['@MDH', 'matchApiV2MatchPost', body],
    queryFn: () => {
      return mdh.matchApiV2MatchPost(body, {
        ...mdhDefaults(),
      });
    },
    enabled,
    retry: 1,
    staleTime: 60000,
  });
