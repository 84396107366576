import {
  SchemaUiConfigEdit,
  SchemaUiConfigType,
} from '@rossum/api-client/schemas';

// using SchemaUiConfigType and SchemaUiConfigEdit as input parameters
// instead of the whole schema object so we can reuse it in SidebarV2
export const isEditableFormulaField = (
  valueSource: SchemaUiConfigType | 'unset',
  editing: SchemaUiConfigEdit
) =>
  (valueSource === 'formula' || valueSource === 'brain') &&
  editing === 'enabled';

export const isEditedFormulaField = (
  valueSource: SchemaUiConfigType | 'unset',
  noRecalculation: boolean | undefined
) =>
  noRecalculation === true &&
  (valueSource === 'formula' || valueSource === 'brain');
