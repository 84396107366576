import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DSResultsResponseResultsItem,
  QueryDTO,
} from 'libs/mdh-api-client/src';
import { useIntl } from 'react-intl';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { getTaskIdFromResponse } from '../utils';
import { GET_QUERIES_QUERY_KEYS } from './useGetQueries';

export const useUpdateDataset = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  return useMutation({
    mutationFn: async ({
      query,
      newQueryName,
      data,
    }: {
      query: QueryDTO;
      newQueryName: string;
      data: DSResultsResponseResultsItem[];
    }) => {
      const queryResponse = await datasetApi.updateQueryApiV2QueryQueryIdPatch(
        query.id,
        {
          name: newQueryName,
          // Infer columns from the updated data.
          columns: Object.keys(data[0] ?? {}).map(name => ({
            name,
            type: 'string',
          })),
          metadata: {
            type: 'manual',
          },
        },
        getDatasetRequestConfig()
      );

      return datasetApi
        .replaceDatasetApiV1DatasetDatasetNamePut(
          query.query.dataset_name,
          {
            encoding: 'UTF-8',
            file: new Blob([JSON.stringify(data)], {
              type: 'application/json',
            }),
          },
          getDatasetRequestConfig()
        )
        .then(response => {
          return {
            taskId: getTaskIdFromResponse(response),
            datasetName: query.query.dataset_name,
            queryId: queryResponse.data.id,
          };
        });
    },
    onSuccess: datasetName => {
      queryClient.invalidateQueries({
        queryKey: ['@MDH', 'dsProxyFindApiV1DataFindPost', datasetName],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: GET_QUERIES_QUERY_KEYS });
    },
    onError: () => {
      notify.error({
        title: intl.formatMessage({
          id: 'features.datasets.hooks.error.update.title',
        }),
      });
    },
  });
};
