import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { getQueryType } from '../../master-data/utils/getQueryType';
import { NotFound } from '../components/NotFound';
import { DatasetTable } from '../dataset-table';
import { DatasetVirtual } from '../dataset-virtual/DatasetVirtual';
import { useGetDatasetParams } from '../hooks/useGetDatasetParams';
import { useGetQueries } from '../hooks/useGetQueries';
import { datasetTableRoute } from '../routes';

export const DatasetDetail = () => {
  const { queryId } = useGetDatasetParams({
    route: datasetTableRoute(),
  });

  const { data: queries } = useGetQueries();
  const query = queries?.find(query => query.id === queryId);

  return query ? (
    getQueryType(query) === 'manual' || getQueryType(query) === 'imported' ? (
      <DatasetTable query={query} />
    ) : (
      <DatasetVirtual query={query} />
    )
  ) : (
    <PageLayoutV2>
      <NotFound />
    </PageLayoutV2>
  );
};
