import { MoreVert, Troubleshoot } from '@rossum/ui/icons';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@rossum/ui/material';
import { GridActionsCellItem } from '@rossum/ui/x-data-grid-pro';
import { compact } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';
import {
  canHaveAttachments,
  isDeletable,
  isPostponable,
  isPurgeable,
  isReExtractable,
  isReprocessable,
} from '../../../redux/modules/annotation/helpers';
import {
  canUserPurgeSelector,
  isUserAnnotatorBetaSelector,
  isUserViewer,
} from '../../../redux/modules/user/selectors';
import { Status } from '../../../types/annotationStatus';
import { attachmentsFeatureSelector } from '../../pricing/selectors';
import { usePermittedActions } from '../annotation-actions/hooks/usePermittedActions';
import DownloadAllOption from '../download-buttons/DownloadAllOption';
import { useAnnotationActionsContext } from '../selection-panel/AnnotationActionContext';
import { AllDocsAnnotation } from '../types';
import BatchLabels from './BatchLabels';

type RowActionsProps = {
  annotation: AllDocsAnnotation;
};

const resolveActionsOrder = ({
  status,
  canDownload,
  canDelete,
  canPurge,
  labelsEnabled,
  isAttachmentsEnabled,
}: {
  status: Status;
  canDownload: boolean;
  canDelete: boolean;
  canPurge: boolean;
  labelsEnabled: boolean;
  isAttachmentsEnabled: boolean;
}) =>
  compact([
    'validate',
    canDownload && 'downloadFile',
    isPostponable(status) && 'postpone',
    labelsEnabled && 'label',
    'move',
    isReprocessable(status) && 'reprocess',
    isReExtractable(status) && 'reExtract',
    isDeletable(status) && canDelete && 'delete',
    isPurgeable(status) && canPurge && 'purge',
    isAttachmentsEnabled && canHaveAttachments(status) && 'addAttachment',
  ]);

const RowActions = ({ annotation }: RowActionsProps) => {
  const { status, content } = annotation;

  const { annotationActions } = useAnnotationActionsContext();

  const isViewer = useSelector(isUserViewer);
  const canUserPurge = useSelector(canUserPurgeSelector);
  const isAnnotatorLimited = useSelector(isUserAnnotatorBetaSelector);
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { permittedActions, isLabelsEnabled } = usePermittedActions();

  const isAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const orderedActions = resolveActionsOrder({
    status,
    canDownload: !isAnnotatorLimited && !!content,
    canDelete: !isViewer,
    labelsEnabled: isLabelsEnabled,
    isAttachmentsEnabled,
    canPurge: canUserPurge,
  });

  const allowedActions = orderedActions.filter(action =>
    permittedActions.includes(action)
  );

  return (
    <>
      <GridActionsCellItem
        icon={<MoreVert />}
        label="Options"
        data-cy="all-document-dashboard-single-row-actions"
        data-id={annotation.id}
        onClick={(e: React.MouseEvent) => {
          setAnchorEl(e.currentTarget);
        }}
      />

      {annotationActions ? (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {DEV_FEATURES_ENABLED ? (
            <MenuItem
              key="smol"
              component={Link}
              sx={{ color: '#fff !important' }}
              to={{ pathname: `/document-v2/${annotation.id}` }}
            >
              <ListItemIcon>
                <Troubleshoot />
              </ListItemIcon>
              <ListItemText
                primary="Mini validation"
                secondary="For development purposes"
              />
            </MenuItem>
          ) : null}
          {allowedActions.map(action => {
            if (action === 'label') {
              return (
                <BatchLabels
                  key={action}
                  selectedAnnotations={[annotation]}
                  onLabel={annotationActions.label}
                  nestedMenu
                >
                  {({ isHovered }) => (
                    <MenuItem
                      selected={isHovered}
                      data-cy={`single-row-action-${action}`}
                    >
                      {intl.formatMessage({
                        id: 'containers.annotationList.selectionPanel.buttons.label',
                      })}
                    </MenuItem>
                  )}
                </BatchLabels>
              );
            }

            if (action === 'downloadFile') {
              return (
                <DownloadAllOption
                  key={action}
                  downloadAll={({ format }) =>
                    annotationActions.download({
                      selectedAnnotationsIds: [annotation.id],
                      format,
                    })
                  }
                  asyncDownload={() =>
                    annotationActions.asyncDownload({
                      annotations: [annotation],
                    })
                  }
                  selectedItemsCount={1}
                  nestedMenu
                >
                  {(setAnchor, isVisible) => (
                    <MenuItem
                      selected={isVisible}
                      onClick={setAnchor}
                      data-cy={`single-row-action-${action}`}
                    >
                      {intl.formatMessage({
                        id: 'components.annotationOverview.actionButtons.downloadFile',
                      })}
                    </MenuItem>
                  )}
                </DownloadAllOption>
              );
            }

            return (
              <MenuItem
                key={action}
                data-cy={`single-row-action-${action}`}
                onClick={() => {
                  setAnchorEl(null);
                  annotationActions[action]({ annotations: [annotation] });
                }}
              >
                <ListItemText>
                  {intl.formatMessage({
                    id: `components.annotationOverview.actionButtons.${
                      action === 'validate' ? 'open' : action
                    }`,
                  })}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </>
  );
};

export default RowActions;
