export const DATASET_ID_PARAM_KEY = 'id';

export const datasetsPath = () => '/settings/datasets' as const;

export const datasetTableRoute = () =>
  `/settings/datasets/:${DATASET_ID_PARAM_KEY}/details` as const;
export const datasetTablePath = (id: string) =>
  `/settings/datasets/${id}/details` as const;

export const datasetSettingsRoute = () =>
  `/settings/datasets/:${DATASET_ID_PARAM_KEY}/settings` as const;
export const datasetSettingsPath = (id: string) =>
  `/settings/datasets/${id}/settings` as const;

export const datasetCreateRoute = () => '/settings/datasets/create' as const;
export const datasetAddImported = () =>
  '/settings/datasets/add-imported' as const;
