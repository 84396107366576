import { SchemaSection } from '@rossum/api-client/schemas';
import { IconDatabase, IconEdit } from '@rossum/ui/icons/tabler';
import {
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { useMemo } from 'react';
import { Control, Controller, useController, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { EXTRACTED_FIELD_TYPES_LINK } from '../../../../constants/values';
import EnumEditorInDrawer from '../../../../containers/Queue/containers/QueueSchema/components/EnumEditorInDrawer';
import { boldText, linebreak, link } from '../../../../lib/formaterValues';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import {
  FieldDataset,
  tryToGetDatasetOptions,
} from '../../../master-data/types/FieldDataset';
import { getLinkStyles } from '../constants';
import { FieldsFormValues } from '../form-model/formModels';
import { DatasetTileContent } from './datasets/DatasetTileContent';

type OptionsTileContentProps = {
  control: Control<FieldsFormValues, undefined>;
  schemaContent: SchemaSection[];
};

const enumTypeOptions = [
  {
    value: 'manual',
    title: 'Create and edit here',
  },
  {
    value: 'dataset',
    title: 'From dataset',
  },
];

export const OptionsTileContent = ({
  control,
  schemaContent,
}: OptionsTileContentProps) => {
  const { field: descriptionField } = useController({
    control,
    name: 'field.description',
  });

  const valueSource = useWatch({
    control,
    name: 'field.valueSource',
  });

  const valueParsed = useMemo(() => {
    return tryToGetDatasetOptions(descriptionField.value);
  }, [descriptionField.value]);

  const optionSource = valueParsed.success ? 'dataset' : 'manual';
  const setOptionSource = (s: string) =>
    s === 'dataset'
      ? descriptionField.onChange(
          JSON.stringify({
            dataset: {
              dataset_name: '',
              id_column: '',
              label_column: '',
            },
          })
        )
      : descriptionField.onChange('');

  const onChangeValueParsed = (newDataset: FieldDataset) =>
    descriptionField.onChange(JSON.stringify({ dataset: newDataset }));

  const intl = useIntl();
  const theme = useTheme();
  return valueSource === 'lookup' ? (
    <DatasetTileContent
      variant="lookup"
      value={
        valueParsed.success
          ? valueParsed.data.dataset
          : {
              dataset_name: '',
              id_column: '',
              label_column: '',
              detail_columns: [],
              filters: [],
              arguments: {},
              additional_columns: [],
            }
      }
      onChange={onChangeValueParsed}
      schemaContent={schemaContent}
    />
  ) : (
    <ContentGroup
      title={intl.formatMessage({
        id: 'features.queueSettings.fields.form.options.title',
      })}
      description={intl.formatMessage(
        {
          id: `features.queueSettings.fields.form.options.${optionSource}.description`,
        },
        {
          boldText,
          linebreak,
          link: link(EXTRACTED_FIELD_TYPES_LINK, getLinkStyles(theme)),
        }
      )}
    >
      <TextField
        label="Source"
        select
        autoComplete="off"
        value={optionSource}
        onChange={e => {
          setOptionSource(e.target.value);
        }}
        inputProps={{
          sx: {
            paddingTop: 1,
            paddingBottom: 1,
          },
        }}
      >
        {enumTypeOptions.map(option => {
          return (
            <MenuItem
              key={`${option.value}-${option.title}`}
              value={option.value}
            >
              <Stack spacing={1} alignItems="center" direction="row">
                <SvgIcon>
                  {option.value === 'dataset' ? <IconDatabase /> : <IconEdit />}
                </SvgIcon>
                <Typography>{option.title}</Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </TextField>

      {valueParsed.success ? (
        <DatasetTileContent
          variant="enum"
          value={valueParsed.data.dataset}
          onChange={onChangeValueParsed}
          schemaContent={schemaContent}
        />
      ) : (
        <Controller
          control={control}
          name="field.options"
          render={({ field: { value, onChange } }) => (
            <EnumEditorInDrawer
              options={value || []}
              onCurrentSchemaPartChange={options => onChange(options)}
              showPasteText={false}
            />
          )}
        />
      )}
    </ContentGroup>
  );
};
