import { useQuery } from '@tanstack/react-query';
import { datasetApi, getDatasetRequestConfig } from '../api';

export const GET_QUERIES_QUERY_KEYS = [
  '@MDH',
  'getQueriesApiV2QueryGet',
] as const;

export const useGetQueries = () => {
  return useQuery({
    queryKey: GET_QUERIES_QUERY_KEYS,
    queryFn: () =>
      datasetApi.getQueriesApiV2QueryGet(getDatasetRequestConfig()),
    select: response => response.data.queries,
  });
};
