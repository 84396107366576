import { SchemaDatapoint } from '@rossum/api-client/schemas';
import { TQueryParameter } from '@rossum/mdh-api-client';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { FieldDatasetValue } from '../../../../master-data/types/FieldDataset';

type ArgumentAutocomplete = {
  onChange: (a: FieldDatasetValue) => void;
  value: FieldDatasetValue | undefined;
  fieldOptions: SchemaDatapoint[];
  argument: TQueryParameter;
};

export const ArgumentAutocomplete = ({
  onChange,
  value,
  fieldOptions,
  argument,
}: ArgumentAutocomplete) => {
  const stableOptions = useMemo(
    () => fieldOptions.filter(op => op.type === argument.type),
    [argument.type, fieldOptions]
  );

  const stableValue = useMemo(
    () =>
      value && value.type === 'const'
        ? value.const
        : fieldOptions.find(op => op.id === value?.field) ?? null,
    [fieldOptions, value]
  );

  return typeof stableValue === 'string' ? (
    // TODO: How to enter values?
    <>Value: {stableValue}</>
  ) : (
    <Autocomplete
      sx={{ flex: 0.5 }}
      options={stableOptions}
      getOptionLabel={v => `${v.label} (field.${v.id})`}
      renderInput={params => (
        <TextField
          {...params}
          label={`Parameter: ${argument.name} (${argument.type})`}
          variant="outlined"
          size="small"
        />
      )}
      value={stableValue}
      onChange={(_, v) => {
        if (v) {
          onChange({ type: 'field', field: v.id });
        }
      }}
      size="small"
    />
  );
};
