import {
  Alert,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { filter, map, mapValues, pickBy, pipe, unique, values } from 'remeda';
import * as z from 'zod';
import useDebounce from '../../../../../utils/hooks/useDebounce';
import { useFindInDataset } from '../../../../master-data/hooks/useFindInDataset';
import { FieldDataset } from '../../../../master-data/types/FieldDataset';

const getError = (e: unknown) => {
  if (e instanceof AxiosError && e.response) {
    const parsed = z.object({ message: z.string() }).safeParse(e.response.data);
    return parsed.success ? parsed.data.message : 'Unknown error';
  }

  return 'Unknown error';
};

export const DatasetPreview = ({ value }: { value: FieldDataset }) => {
  const [testValues, setTestValues] = useState<Record<string, string>>({});
  const debouncedTestValues = useDebounce(testValues, 500);

  const {
    data: objects,
    error,
    isSuccess,
    isError,
  } = useFindInDataset(
    {
      query_name: value.dataset_name,
      arguments: pipe(
        value.arguments,
        mapValues(argument =>
          argument.type === 'const'
            ? argument.const
            : debouncedTestValues[argument.field] || undefined
        ),
        pickBy(argument => argument !== undefined)
      ),
    },
    { enabled: true }
  );

  const inputs = pipe(
    values(value.arguments),
    filter(filter => filter.type === 'field'),
    map(({ field }) => field),
    unique(),
    map(field => (
      <TextField
        fullWidth
        size="small"
        value={testValues[field] ?? ''}
        onChange={e =>
          setTestValues(prev => ({
            ...prev,
            [field]: e.target.value,
          }))
        }
        label={`field.${field}`}
      />
    ))
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h6">Preview</Typography>
      {inputs.length > 0 ? <Stack spacing={1}>{inputs}</Stack> : null}

      {isSuccess ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Label</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {// eslint-disable-next-line @typescript-eslint/no-explicit-any
              objects.data?.results.slice(0, 10)?.map((object: any) => (
                <TableRow key={object[value.id_column]}>
                  <TableCell component="th" scope="row" size="small">
                    {object[value.id_column]}
                  </TableCell>
                  <TableCell align="right" size="small">
                    {object[value.label_column]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : isError ? (
        <Alert variant="outlined" color="error">
          {getError(error)}
        </Alert>
      ) : null}
    </Stack>
  );
};
