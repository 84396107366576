import {
  IconAlignJustified,
  IconBorderCorners,
  IconCrystalBall,
  IconCustomAlignJustifiedCheck,
  IconCustomBorderCornersCheck,
  IconCustomButton,
  IconCustomCrystalBallCheck,
  IconCustomDatabaseCheck,
  IconCustomLayoutGridCheck,
  IconCustomLayoutListCheck,
  IconCustomMathFunctionCheck,
  IconCustomMathFunctionEdit,
  IconCustomTableCheck,
  IconDatabaseSearch,
  IconLayoutGrid,
  IconLayoutList,
  IconMathFunction,
  IconProgress,
  IconProgressCheck,
  IconSection,
  IconTable,
} from '@rossum/ui/icons/tabler';
import { createSvgIcon, SvgIcon, SvgIconProps } from '@rossum/ui/material';

export type SchemaFieldIconType =
  | 'simpleValue'
  | 'multiValue'
  | 'lineItem'
  | 'unset'
  | 'button'
  | 'captured'
  | 'manual'
  | 'data'
  | 'formula'
  | 'brain'
  | 'lookup';

export type SchemaFieldIconVariant = 'default' | 'confirmed' | 'edited';

const fieldIcons: Record<
  SchemaFieldIconType,
  Partial<Record<SchemaFieldIconVariant, typeof SvgIcon | null>>
> = {
  // field types
  simpleValue: {
    default: createSvgIcon(<IconSection />, 'IconSection'),
  },
  multiValue: {
    default: createSvgIcon(<IconLayoutList />, 'IconLayoutList'),
    confirmed: createSvgIcon(
      <IconCustomLayoutListCheck />,
      'IconCustomLayoutListCheck'
    ),
  },
  lineItem: {
    default: createSvgIcon(<IconTable />, 'IconTable'),
    confirmed: createSvgIcon(<IconCustomTableCheck />, 'IconCustomTableCheck'),
  },
  button: {
    default: createSvgIcon(<IconCustomButton />, 'CustomButton'),
  },
  // value sources
  unset: {
    default: createSvgIcon(<IconProgress />, 'IconProgress'),
    confirmed: createSvgIcon(<IconProgressCheck />, 'IconProgressCheck'),
  },
  captured: {
    default: createSvgIcon(<IconBorderCorners />, 'IconBorderCorners'),
    confirmed: createSvgIcon(
      <IconCustomBorderCornersCheck />,
      'IconCustomBorderCornersCheck'
    ),
  },
  manual: {
    default: createSvgIcon(<IconAlignJustified />, 'IconAlignJustified'),
    confirmed: createSvgIcon(
      <IconCustomAlignJustifiedCheck />,
      'IconCustomAlignJustifiedCheck'
    ),
  },
  data: {
    default: createSvgIcon(<IconLayoutGrid />, 'IconLayoutGrid'),
    confirmed: createSvgIcon(
      <IconCustomLayoutGridCheck />,
      'IconCustomLayoutGridCheck'
    ),
    edited: null,
  },
  formula: {
    default: createSvgIcon(<IconMathFunction />, 'IconMathFunction'),
    confirmed: createSvgIcon(
      <IconCustomMathFunctionCheck />,
      'IconCustomMathFunctionCheck'
    ),
    edited: createSvgIcon(<IconCustomMathFunctionEdit />, 'IconMathFunction'),
  },
  brain: {
    default: createSvgIcon(<IconCrystalBall />, 'IconCrystalBall'),
    confirmed: createSvgIcon(
      <IconCustomCrystalBallCheck />,
      'IconCustomCrystalBallCheck'
    ),
    edited: createSvgIcon(
      <IconCustomCrystalBallCheck />,
      'IconCustomCrystalBallCheck'
    ),
  },
  lookup: {
    default: createSvgIcon(<IconDatabaseSearch />, 'IconDatabaseSearch'),
    confirmed: createSvgIcon(
      <IconCustomDatabaseCheck />,
      'IconCustomDatabaseCheck'
    ),
    edited: null,
  },
};

type SchemaFieldIconProps = SvgIconProps & {
  type: SchemaFieldIconType;
  variant?: SchemaFieldIconVariant;
};

export const SchemaFieldIcon = ({
  type,
  variant = 'default',
  ...rest
}: SchemaFieldIconProps) => {
  const Icon = fieldIcons[type][variant] ?? null;

  if (!Icon) {
    return null;
  }

  return <Icon {...rest} />;
};
