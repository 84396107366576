import {
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { DragIndicatorRounded } from '@rossum/ui/icons';
import { IconTrash } from '@rossum/ui/icons/tabler';
import {
  Autocomplete,
  Button,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { difference } from 'remeda';
import SortableWrapper, {
  DragHandleProps,
} from '../../../../../components/Dnd/SortableWrapper';

type AdditionalColumn = {
  column: string;
  label: string;
};

type AdditionalColumnsProps = {
  value: AdditionalColumn[];
  onChange: (a: AdditionalColumn[]) => void;
  columnOptions: string[];
};

const AdditionalColumn = ({
  column,
  onChangeColumn,
  columnOptions,
  dragHandleProps,
}: {
  column: AdditionalColumn;
  onChangeColumn: (a: AdditionalColumn | null) => void;
  columnOptions: string[];
  dragHandleProps?: DragHandleProps;
}) => {
  return (
    <Stack key={column.column} flex={1} direction="row" spacing={1} p={1}>
      <IconButton
        disableRipple
        {...(dragHandleProps?.listeners ?? {})}
        {...(dragHandleProps?.attributes ?? {})}
      >
        <DragIndicatorRounded />
      </IconButton>

      <TextField
        label="Label"
        sx={{ flex: 0.5 }}
        size="small"
        value={column.label}
        onChange={e =>
          onChangeColumn({
            column: column.column,
            label: e.currentTarget.value,
          })
        }
      />
      <Autocomplete
        sx={{ flex: 0.5 }}
        options={columnOptions}
        renderInput={params => (
          <TextField
            {...params}
            label="Column"
            variant="outlined"
            size="small"
          />
        )}
        value={column.column}
        onChange={(_, newColumn) => {
          if (newColumn) {
            onChangeColumn({ column: newColumn, label: column.label });
          }
        }}
        size="small"
      />
      <IconButton size="small" onClick={() => onChangeColumn(null)}>
        <SvgIcon>
          <IconTrash />
        </SvgIcon>
      </IconButton>
    </Stack>
  );
};

export const AdditionalColumns = ({
  value,
  onChange,
  columnOptions,
}: AdditionalColumnsProps) => {
  const filteredColumnOptions = difference(
    columnOptions,
    value.map(({ column }) => column)
  );

  const [draggedAction, setDraggedAction] = useState<
    (typeof value)[number] | null
  >(null);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = value.findIndex(a => a.column === active.id);
      const overIndex = value.findIndex(a => a.column === over?.id);

      const reorderedColumns = arrayMove(value, activeIndex, overIndex);

      onChange(reorderedColumns);
    }
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Additional columns</Typography>

      <Stack>
        {value.length === 0 ? (
          <Typography variant="body2" color="secondary">
            You can add additional columns that will be displayed on the
            validation screen below the field.
          </Typography>
        ) : (
          <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={({ active }) => {
              setDraggedAction(value.find(a => a.column === active.id) ?? null);
            }}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext items={value.map(({ column }) => column)}>
              {value.map((column, index) => (
                <SortableWrapper
                  id={column.column}
                  key={column.column}
                  render={dragHandleProps => (
                    <AdditionalColumn
                      onChangeColumn={newColumn => {
                        onChange(
                          newColumn
                            ? // Update column
                              value.map((v, i) => (i === index ? newColumn : v))
                            : // Delete column
                              value.filter((_, i) => i !== index)
                        );
                      }}
                      column={column}
                      columnOptions={
                        column.column
                          ? [...filteredColumnOptions, column.column]
                          : filteredColumnOptions
                      }
                      dragHandleProps={dragHandleProps}
                    />
                  )}
                />
              ))}
            </SortableContext>
            <DragOverlay dropAnimation={defaultDropAnimation}>
              {draggedAction ? (
                <Paper elevation={4}>
                  <AdditionalColumn
                    onChangeColumn={() => {}}
                    column={draggedAction}
                    columnOptions={[]}
                  />
                </Paper>
              ) : null}
            </DragOverlay>
          </DndContext>
        )}
      </Stack>

      <Stack direction="row" alignSelf="end">
        <Button
          variant="contained"
          color="primary"
          disabled={filteredColumnOptions.length === 0}
          onClick={() => {
            const firstColumn = filteredColumnOptions[0];
            if (firstColumn) {
              onChange([...value, { label: '', column: firstColumn }]);
            }
          }}
        >
          Add column
        </Button>
      </Stack>
    </Stack>
  );
};
