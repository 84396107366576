import {
  DatasetAggregateOutput,
  QueryDTO,
  TQueryColumn,
  TQueryParameter,
} from '@rossum/mdh-api-client';
import { isTruthy, last } from 'remeda';

const findArguments = (input: unknown): string[] => {
  if (input && typeof input === 'object') {
    return Object.values(input).flatMap(value => findArguments(value));
  }
  if (input && typeof input === 'string') {
    return [
      ...input
        .matchAll(/\{([a-zA-Z_]+)\}/g)
        .map(elem => elem[1])
        .filter(isTruthy),
    ];
  }
  return [];
};

/**
 * Query can have variables inside brackets: {variable}
 * This goes through query values and matches such patterns.
 */
export const getArgumentOptions = (
  datasetValue: QueryDTO | null
): TQueryParameter[] => {
  if (datasetValue?.parameters?.length) {
    return datasetValue.parameters;
  }

  // TODO: This heuristic can maybe be removed now.
  return findArguments(
    datasetValue
      ? datasetValue.query.type === 'dataset_find'
        ? datasetValue.query.find
        : datasetValue.query.type === 'dataset_aggregate'
          ? datasetValue.query.aggregate
          : undefined
      : undefined
  ).map(arg => ({ name: arg, type: 'string' }));
};

const tryToGetProjection = (query: DatasetAggregateOutput) => {
  const projection = last(query.aggregate.pipeline)?.$project;
  return projection && typeof projection === 'object' ? projection : undefined;
};

/**
 * Attempt to determine the columns from the query.
 * For find query, hope that there is "projection" parameter.
 * For aggregate query, hope that the pipeline ends with $project.
 * Otherwise, panic.
 */
export const getColumnOptions = (
  datasetValue: QueryDTO | null
): TQueryColumn[] => {
  if (datasetValue?.columns?.length) {
    return datasetValue.columns;
  }

  // TODO: This heuristic can maybe be removed now.
  const projection = datasetValue
    ? datasetValue.query.type === 'dataset_find'
      ? datasetValue.query.find.projection
      : datasetValue.query.type === 'dataset_aggregate'
        ? tryToGetProjection(datasetValue.query)
        : undefined
    : undefined;
  return projection
    ? Object.keys(projection).map(col => ({
        name: col,
        type: 'string',
      }))
    : [];
};
