import { getMasterDataHub } from '@rossum/mdh-api-client';
import { AxiosRequestConfig } from 'axios';
import { apiUrl } from '../../constants/config';
import { getAuthToken } from '../../lib/token';

export const datasetApi = getMasterDataHub();

export const getDatasetRequestConfig: () => AxiosRequestConfig = () => ({
  baseURL: apiUrl.replace('api/v1', ''),
  headers: { Authorization: `Bearer ${getAuthToken()}` },
});
