import { QueryDTO } from '@rossum/mdh-api-client';
import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { getTaskIdFromResponse } from '../utils';

export const useDeleteDataset = () => {
  const intl = useIntl();
  return useMutation({
    mutationFn: async ({ query }: { query: QueryDTO }) => {
      const datasetName = query.query.dataset_name;

      const queryResponse = await datasetApi.updateQueryApiV2QueryQueryIdPatch(
        query.id,
        {
          metadata: {
            ...query.metadata,
            delete_pending: true,
          },
        },
        getDatasetRequestConfig()
      );

      return datasetApi
        .deleteDatasetApiV1DatasetDatasetNameDelete(
          datasetName,
          getDatasetRequestConfig()
        )
        .then(response => {
          return {
            taskId: getTaskIdFromResponse(response),
            datasetName,
            queryId: queryResponse.data.id,
          };
        });
    },
    onError: (_, { query }) => {
      notify.error({
        title: intl.formatMessage(
          {
            id: 'features.datasets.context.taskNotification.delete.error.title',
          },
          { queryName: query.name }
        ),
      });
    },
  });
};
