import { CreateQueryDTO } from '@rossum/mdh-api-client';
import { Autocomplete, Button, Stack, TextField } from '@rossum/ui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { notify } from '../../toaster';
import { datasetApi, getDatasetRequestConfig } from '../api';
import { GET_QUERIES_QUERY_KEYS } from '../hooks/useGetQueries';

export const DatasetAddImported = () => {
  const queryClient = useQueryClient();
  const { data: datasets } = useQuery({
    queryKey: ['@MDH', 'getDatasetsApiV2DatasetGet'],
    queryFn: () =>
      datasetApi.getDatasetsApiV2DatasetGet(getDatasetRequestConfig()),
    select: response => response.data.datasets,
  });

  const { mutate: addQuery } = useMutation({
    mutationFn: (payload: CreateQueryDTO) =>
      datasetApi.createQueryApiV2QueryPost(payload, getDatasetRequestConfig()),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GET_QUERIES_QUERY_KEYS });
    },
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      datasetName: null,
      queryName: '',
    },
  });

  const onSubmit = (formValues: {
    datasetName: string | null;
    queryName: string;
  }) => {
    if (formValues.datasetName) {
      addQuery(
        {
          name: formValues.queryName,
          query: {
            dataset_name: formValues.datasetName,
            type: 'dataset_find',
            find: { query: {} },
          },
          metadata: {
            type: 'imported',
          },
        },
        {
          onSuccess: () => {
            notify.success({ title: 'Döne.' });
          },
        }
      );
    }
  };

  return datasets ? (
    <PageLayoutV2>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} my={4}>
        <ContentGroup
          title="Add imported dataset"
          description="If you have imported a dataset directly via API or master data hub, you will need to add it to Rossum here."
        >
          <TextFieldControl
            ControllerProps={{ control, name: 'queryName' }}
            label="Dataset name"
          />

          <Controller
            control={control}
            name="datasetName"
            render={({ field }) => (
              <Autocomplete
                getOptionLabel={dataset => dataset.dataset_name}
                options={datasets}
                value={datasets?.find(
                  dataset => dataset.dataset_name === field.value
                )}
                isOptionEqualToValue={(option, value) =>
                  option.dataset_name === value.dataset_name
                }
                onChange={(_, v) => field.onChange(v?.dataset_name)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Imported file"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          />

          <Button variant="contained" color="primary" type="submit">
            Add imported dataset
          </Button>
        </ContentGroup>
      </Stack>
    </PageLayoutV2>
  ) : null;
};
